import request from "../request";
import { withGlobalOrg, withGlobalOrgWhere, splitLargeWhere } from "./_helpers";
import { doInfiniteRequest } from "../../constants/request";

/* eslint-disable camelcase */

export async function create(values) {
  const { body } = await request.post("page", {
    body: withGlobalOrg(values),
  });

  return body;
}

export async function get({ id, query }) {
  const { body } = await request.get(`page/${id}`, {
    query,
  });
  return body;
}

export async function list(q) {
  const query = withGlobalOrgWhere(q);
  query.$where.active = true;
  const { body } = await request.get("page", {
    query,
  });
  return body;
}

export async function listAll(query) {
  if (query?.$where?.organization_id == null) {
    query = withGlobalOrgWhere(query);
  }
  return await doInfiniteRequest("page", query);
}

export async function update({ id, ...values }) {
  const { body } = await request.put(`page/${id}`, {
    body: withGlobalOrg(values),
  });
  return body;
}

export async function moveSetsToProject({ setId, projectId }) {
  const { body } = await request.post(`set/${setId}/move`, {
    body: { project_id: projectId },
  });
  return body;
}

export async function batchUpdate({ ids, ...values }) {
  return await splitLargeWhere("put", "page", {
    body: withGlobalOrg({ ...values }),
    query: {
      $where: {
        id: ids,
      },
    },
  });
}

export async function rename(data) {
  const { body } = await request.put(`page/rename`, {
    body: data,
  });
  return body;
}

export async function remove({ id }) {
  const { body } = await request.delete(`page/${id}`);
  return body;
}

export async function batchRemove(query) {
  return await splitLargeWhere("delete", "page", { query });
}

export async function stitch(ids) {
  const { body } = await request.post("page/stitch", {
    body: ids,
  });

  return body;
}

export async function duplicate({ id, setId }) {
  const { body } = await request.post(`page/${id}/set/${setId}/copy`);
  return body;
}

export async function getSetFolders(setId) {
  const { body } = await request.get(`page/folder`, {
    query: withGlobalOrgWhere({ $where: { set_id: setId }, $limit: 100 }),
  });
  return body;
}

export async function createFolder({ name, setId, projectId }) {
  const { body } = await request.post(`page/folder`, {
    body: withGlobalOrg({ name, set_id: setId, project_id: projectId }),
  });
  return body;
}

export async function updateFolder({ id, name }) {
  const { body } = await request.put(`page/folder/${id}`, {
    body: withGlobalOrg({ name }),
  });
  return body;
}

export async function removeFolder({ id }) {
  const { body } = await request.delete(`page/folder/${id}`);
  return body;
}

export async function requestTilling({ id, priority }) {
  const { body } = await request.post(`tiling/request`, {
    body: {
      page_id: id,
      priority,
    },
  });
  return body;
}
