import {
  FEET_TO_INCHES,
  YARDS_TO_INCHES,
  INCHES_TO_CENTIMETERS,
} from "../../../consts/geometry";
import * as CLS_CONST from "../../../consts/classifications";

export const FORUMULA_TYPES = {
  AREA: "EDITING_AREA",
  LINE: "EDITING_LINE",
  COUNT: "EDITING_COUNT",
};

export const TAG_TYPES = {
  FORMULA: "formula",
  FUNCTION: "function",
  STATIC_FORMULA: "static_formula",
  QUANTITY_FORMULA: "quantity_formula",
  OPERATOR: "operator",
  NUMBER: "number",
  SPACE: "space",
  INVALID: "invalid",
};

export const QUANTITY_FORMULAS: { [key: string]: string[] } = {
  [CLS_CONST.QUANTITY1]: [],
  [CLS_CONST.QUANTITY2]: ["QUANTITY1"],
  [CLS_CONST.QUANTITY3]: ["QUANTITY1", "QUANTITY2"],
  [CLS_CONST.QUANTITY4]: ["QUANTITY1", "QUANTITY2", "QUANTITY3"],
};

export const QUANTITY_FORMULAS_SET = new Set([
  "QUANTITY1",
  "QUANTITY2",
  "QUANTITY3",
  "QUANTITY4",
]);

export const QUANTITY_FORMULAS_CLS_MAP: { [key: string]: string } = {
  QUANTITY1: CLS_CONST.QUANTITY1FORUMULA,
  QUANTITY2: CLS_CONST.QUANTITY2FORUMULA,
  QUANTITY3: CLS_CONST.QUANTITY3FORUMULA,
  QUANTITY4: CLS_CONST.QUANTITY4FORUMULA,
};

export const QUANTITY_FORMULAS_CLS_QMAP: { [key: string]: string } = {
  QUANTITY1: CLS_CONST.QUANTITY1,
  QUANTITY2: CLS_CONST.QUANTITY2,
  QUANTITY3: CLS_CONST.QUANTITY3,
  QUANTITY4: CLS_CONST.QUANTITY4,
};

export const AREA_FORMULAS = [
  "Area_IN2",
  "Area_SF",
  "Area_SY",
  "Area_100_SF",
  "Area_M2",
  "Area_CM2",
  "Area_MM2",
  "Perimeter_IN",
  "Perimeter_FT",
  "Perimeter_YD",
  "Perimeter_M",
  "Perimeter_CM",
  "Perimeter_MM",
  "Volume_CF",
  "Volume_CY",
  "Volume_M3",
  "Perimeter_Surface_IN2",
  "Perimeter_Surface_SF",
  "Perimeter_Surface_SY",
  "Perimeter_Surface_M2",
  "Perimeter_Surface_CM2",
  "Perimeter_Surface_MM2",
  "Perimeter_Surface_SQ",
  "Tile_Count",
  "Slope_Factor",
  "Height_IN",
  "Height_FT",
  "Height_YD",
  "Height_M",
  "Height_CM",
  "Height_MM",
  "Offset_IN",
  "Offset_FT",
  "Offset_YD",
  "Offset_M",
  "Offset_CM",
  "Offset_MM",
  "Tile_Gap_IN",
  "Tile_Gap_FT",
  "Tile_Gap_YD",
  "Tile_Gap_M",
  "Tile_Gap_CM",
  "Tile_Gap_MM",
  "Tile_Height_IN",
  "Tile_Height_FT",
  "Tile_Height_YD",
  "Tile_Height_M",
  "Tile_Height_CM",
  "Tile_Height_MM",
  "Tile_Width_IN",
  "Tile_Width_FT",
  "Tile_Width_YD",
  "Tile_Width_M",
  "Tile_Width_CM",
  "Tile_Width_MM",
  "Count_EA",
];

export const LINE_FORMULAS = [
  "Length_IN",
  "Length_FT",
  "Length_YD",
  "Length_M",
  "Length_CM",
  "Length_MM",
  "Vertical_Area_1Side_IN2",
  "Vertical_Area_1Side_SF",
  "Vertical_Area_1Side_SY",
  "Vertical_Area_1Side_M2",
  "Vertical_Area_1Side_CM2",
  "Vertical_Area_1Side_MM2",
  "Vertical_Area_2Sides_IN2",
  "Vertical_Area_2Sides_SF",
  "Vertical_Area_2Sides_SY",
  "Vertical_Area_2Sides_M2",
  "Vertical_Area_2Sides_CM2",
  "Vertical_Area_2Sides_MM2",
  "Vertical_Area_AllSides_IN2",
  "Vertical_Area_AllSides_SF",
  "Vertical_Area_AllSides_SY",
  "Vertical_Area_AllSides_M2",
  "Vertical_Area_AllSides_CM2",
  "Vertical_Area_AllSides_MM2",
  "Horizontal_Area_IN2",
  "Horizontal_Area_SF",
  "Horizontal_Area_SY",
  "Horizontal_Area_M2",
  "Horizontal_Area_CM2",
  "Horizontal_Area_MM2",
  "Volume_CF",
  "Volume_CY",
  "Volume_M3",
  "Perimeter_IN",
  "Perimeter_FT",
  "Perimeter_YD",
  "Perimeter_M",
  "Perimeter_CM",
  "Perimeter_MM",
  "Height_IN",
  "Height_FT",
  "Height_YD",
  "Height_M",
  "Height_CM",
  "Height_MM",
  "Thickness_IN",
  "Thickness_FT",
  "Thickness_YD",
  "Thickness_M",
  "Thickness_CM",
  "Thickness_MM",
  "Offset_IN",
  "Offset_FT",
  "Offset_YD",
  "Offset_M",
  "Offset_CM",
  "Offset_MM",
  "Slope_Factor",
  "Count_EA",
];

export const COUNT_FORMULAS = [
  "Count_EA",
  "Total_Height_IN",
  "Total_Height_FT",
  "Total_Height_YD",
  "Total_Height_M",
  "Total_Height_CM",
  "Total_Height_MM",
  "Area_IN2",
  "Area_SF",
  "Area_SY",
  "Area_M2",
  "Area_CM2",
  "Area_MM2",
  "Perimeter_IN",
  "Perimeter_FT",
  "Perimeter_YD",
  "Perimeter_M",
  "Perimeter_CM",
  "Perimeter_MM",
  "Vertical_Area_AllSides_IN2",
  "Vertical_Area_AllSides_SF",
  "Vertical_Area_AllSides_SY",
  "Vertical_Area_AllSides_M2",
  "Vertical_Area_AllSides_CM2",
  "Vertical_Area_AllSides_MM2",
  "Volume_CF",
  "Volume_CY",
  "Volume_M3",
  "Height_IN",
  "Height_FT",
  "Height_YD",
  "Height_M",
  "Height_CM",
  "Height_MM",
  "Width_IN",
  "Width_FT",
  "Width_YD",
  "Width_M",
  "Width_CM",
  "Width_MM",
  "Rectangle_Length_IN",
  "Rectangle_Length_FT",
  "Rectangle_Length_YD",
  "Rectangle_Length_M",
  "Rectangle_Length_CM",
  "Rectangle_Length_MM",
  "Offset_IN",
  "Offset_FT",
  "Offset_YD",
  "Offset_M",
  "Offset_CM",
  "Offset_MM",
];

export const ALLOWED_FORMULAS = (type: string) =>
  type === FORUMULA_TYPES.AREA
    ? AREA_FORMULAS
    : type === FORUMULA_TYPES.LINE
    ? LINE_FORMULAS
    : COUNT_FORMULAS;

export const ALLOWED_FORMULAS_SET = (type: string) =>
  new Set(ALLOWED_FORMULAS(type));

export const ALLOWED_FUNCTIONS = ["SQRT", "POW"];
export const ALLOWED_FUNCTIONS_SET = new Set(ALLOWED_FUNCTIONS);
export const ALLOWED_FUNCTIONS_INDICATOR = {
  [ALLOWED_FUNCTIONS[0]]: "()",
  [ALLOWED_FUNCTIONS[1]]: "(,)",
};
export const ALLOWED_FUNCTIONS_INDICATOR_PADDING = {
  [ALLOWED_FUNCTIONS[0]]: 2,
  [ALLOWED_FUNCTIONS[1]]: 3,
};

export const ALL_ALLOWED_STRINGS = [
  ...ALLOWED_FUNCTIONS,
  ...AREA_FORMULAS,
  ...LINE_FORMULAS,
  ...COUNT_FORMULAS,
];
export const ALL_ALLOWED_STRINGS_SET = new Set(ALL_ALLOWED_STRINGS);
export const ALL_ALLOWED_STRINGS_PATTERNS = new RegExp(
  `^(${ALL_ALLOWED_STRINGS.join("|")})(\\()?`
);
export const NUMBERS_PATTERN = /^[+-]?\d+([.,]\d+)?$/;
export const MATH_TOKENS = ["=", "+", "-", "*", "/", "%"];
export const MATH_OPERATORS = [...MATH_TOKENS, "(", ")", ","];

/**
 *
 *
 *
 *
 *
 *
 *
 * * FORMULAS CONST
 *
 *
 *
 *
 *
 */

export const FORMUL_FUNCTIONS_MAP: { [key: string]: string } = {
  POW: `Math.pow`,
  SQRT: `Math.sqrt`,
};

export const FORMULA_FUNCTIONS_KEYS: { [key: string]: string } = {
  Slope_Factor: "slope_factor",

  Tile_Gap_IN: "tileGridGap",
  Tile_Gap_FT: "tileGridGap",
  Tile_Gap_YD: "tileGridGap",
  Tile_Gap_M: "tileGridGap",
  Tile_Gap_CM: "tileGridGap",
  Tile_Gap_MM: "tileGridGap",

  Tile_Height_IN: "tileGridVer",
  Tile_Height_FT: "tileGridVer",
  Tile_Height_YD: "tileGridVer",
  Tile_Height_M: "tileGridVer",
  Tile_Height_CM: "tileGridVer",
  Tile_Height_MM: "tileGridVer",

  Tile_Width_IN: "tileGridHor",
  Tile_Width_FT: "tileGridHor",
  Tile_Width_YD: "tileGridHor",
  Tile_Width_M: "tileGridHor",
  Tile_Width_CM: "tileGridHor",
  Tile_Width_MM: "tileGridHor",

  Height_IN: "height",
  Height_FT: "height",
  Height_YD: "height",
  Height_M: "height",
  Height_CM: "height",
  Height_MM: "height",

  Thickness_IN: "thikness",
  Thickness_FT: "thikness",
  Thickness_YD: "thikness",
  Thickness_M: "thikness",
  Thickness_CM: "thikness",
  Thickness_MM: "thikness",

  Width_IN: "width",
  Width_FT: "width",
  Width_YD: "width",
  Width_M: "width",
  Width_CM: "width",
  Width_MM: "width",

  Rectangle_Length_IN: "length",
  Rectangle_Length_FT: "length",
  Rectangle_Length_YD: "length",
  Rectangle_Length_M: "length",
  Rectangle_Length_CM: "length",
  Rectangle_Length_MM: "length",
};

export const FORUMULA_FUNCTIONS: {
  [key: string]: (feature: any, cls: any) => any;
} = {
  Slope_Factor: (feature: any, _cls: any) =>
    feature?.properties?.slope_factor ?? 1,

  Tile_Gap_IN: (_feature: any, cls: any) => cls?.tileGridGap ?? 0,
  Tile_Gap_FT: (_feature: any, cls: any) =>
    (cls?.tileGridGap ?? 0) / FEET_TO_INCHES,
  Tile_Gap_YD: (_feature: any, cls: any) =>
    (cls?.tileGridGap ?? 0) / YARDS_TO_INCHES,
  Tile_Gap_M: (_feature: any, cls: any) =>
    ((cls?.tileGridGap ?? 0) * INCHES_TO_CENTIMETERS) / 100,
  Tile_Gap_CM: (_feature: any, cls: any) =>
    (cls?.tileGridGap ?? 0) * INCHES_TO_CENTIMETERS,
  Tile_Gap_MM: (_feature: any, cls: any) =>
    (cls?.tileGridGap ?? 0) * INCHES_TO_CENTIMETERS * 10,

  Tile_Height_IN: (_feature: any, cls: any) => cls?.tileGridVer ?? 0,
  Tile_Height_FT: (_feature: any, cls: any) =>
    (cls?.tileGridVer ?? 0) / FEET_TO_INCHES,
  Tile_Height_YD: (_feature: any, cls: any) =>
    (cls?.tileGridVer ?? 0) / YARDS_TO_INCHES,
  Tile_Height_M: (_feature: any, cls: any) =>
    ((cls?.tileGridVer ?? 0) * INCHES_TO_CENTIMETERS) / 100,
  Tile_Height_CM: (_feature: any, cls: any) =>
    (cls?.tileGridVer ?? 0) * INCHES_TO_CENTIMETERS,
  Tile_Height_MM: (_feature: any, cls: any) =>
    (cls?.tileGridVer ?? 0) * INCHES_TO_CENTIMETERS * 10,

  Tile_Width_IN: (_feature: any, cls: any) => cls?.tileGridHor ?? 0,
  Tile_Width_FT: (_feature: any, cls: any) =>
    (cls?.tileGridHor ?? 0) / FEET_TO_INCHES,
  Tile_Width_YD: (_feature: any, cls: any) =>
    (cls?.tileGridHor ?? 0) / YARDS_TO_INCHES,
  Tile_Width_M: (_feature: any, cls: any) =>
    ((cls?.tileGridHor ?? 0) * INCHES_TO_CENTIMETERS) / 100,
  Tile_Width_CM: (_feature: any, cls: any) =>
    (cls?.tileGridHor ?? 0) * INCHES_TO_CENTIMETERS,
  Tile_Width_MM: (_feature: any, cls: any) =>
    (cls?.tileGridHor ?? 0) * INCHES_TO_CENTIMETERS * 10,

  Height_IN: (_feature: any, cls: any) => cls?.height ?? 0,
  Height_FT: (_feature: any, cls: any) => (cls?.height ?? 0) / FEET_TO_INCHES,
  Height_YD: (_feature: any, cls: any) => (cls?.height ?? 0) / YARDS_TO_INCHES,
  Height_M: (_feature: any, cls: any) =>
    ((cls?.height ?? 0) * INCHES_TO_CENTIMETERS) / 100,
  Height_CM: (_feature: any, cls: any) =>
    (cls?.height ?? 0) * INCHES_TO_CENTIMETERS,
  Height_MM: (_feature: any, cls: any) =>
    (cls?.height ?? 0) * INCHES_TO_CENTIMETERS * 10,

  Offset_IN: (_feature: any, cls: any) => cls?.offset ?? 0,
  Offset_FT: (_feature: any, cls: any) => (cls?.offset ?? 0) / FEET_TO_INCHES,
  Offset_YD: (_feature: any, cls: any) => (cls?.offset ?? 0) / YARDS_TO_INCHES,
  Offset_M: (_feature: any, cls: any) =>
    ((cls?.offset ?? 0) * INCHES_TO_CENTIMETERS) / 100,
  Offset_CM: (_feature: any, cls: any) =>
    (cls?.offset ?? 0) * INCHES_TO_CENTIMETERS,
  Offset_MM: (_feature: any, cls: any) =>
    (cls?.offset ?? 0) * INCHES_TO_CENTIMETERS * 10,

  Thickness_IN: (_feature: any, cls: any) => cls?.thikness ?? 0,
  Thickness_FT: (_feature: any, cls: any) =>
    (cls?.thikness ?? 0) / FEET_TO_INCHES,
  Thickness_YD: (_feature: any, cls: any) =>
    (cls?.thikness ?? 0) / YARDS_TO_INCHES,
  Thickness_M: (_feature: any, cls: any) =>
    ((cls?.thikness ?? 0) * INCHES_TO_CENTIMETERS) / 100,
  Thickness_CM: (_feature: any, cls: any) =>
    (cls?.thikness ?? 0) * INCHES_TO_CENTIMETERS,
  Thickness_MM: (_feature: any, cls: any) =>
    (cls?.thikness ?? 0) * INCHES_TO_CENTIMETERS * 10,

  Width_IN: (_feature: any, cls: any) => cls?.width ?? 0,
  Width_FT: (_feature: any, cls: any) => (cls?.width ?? 0) / FEET_TO_INCHES,
  Width_YD: (_feature: any, cls: any) => (cls?.width ?? 0) / YARDS_TO_INCHES,
  Width_M: (_feature: any, cls: any) =>
    ((cls?.width ?? 0) * INCHES_TO_CENTIMETERS) / 100,
  Width_CM: (_feature: any, cls: any) =>
    (cls?.width ?? 0) * INCHES_TO_CENTIMETERS,
  Width_MM: (_feature: any, cls: any) =>
    (cls?.width ?? 0) * INCHES_TO_CENTIMETERS * 10,

  Rectangle_Length_IN: (_feature: any, cls: any) => cls?.length ?? 0,
  Rectangle_Length_FT: (_feature: any, cls: any) =>
    (cls?.length ?? 0) / FEET_TO_INCHES,
  Rectangle_Length_YD: (_feature: any, cls: any) =>
    (cls?.length ?? 0) / YARDS_TO_INCHES,
  Rectangle_Length_M: (_feature: any, cls: any) =>
    ((cls?.length ?? 0) * INCHES_TO_CENTIMETERS) / 100,
  Rectangle_Length_CM: (_feature: any, cls: any) =>
    (cls?.length ?? 0) * INCHES_TO_CENTIMETERS,
  Rectangle_Length_MM: (_feature: any, cls: any) =>
    (cls?.length ?? 0) * INCHES_TO_CENTIMETERS * 10,
};

export const FORMULA_EXPRESSIONS: {
  [key: string]: { measure: string; unitOverride?: string };
} = {
  Area_IN2: {
    measure: CLS_CONST.QUANTITY_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_INCH,
  },
  Area_SF: {
    measure: CLS_CONST.QUANTITY_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_FEET,
  },
  Area_100_SF: {
    measure: CLS_CONST.QUANTITY_AREA,
    unitOverride: CLS_CONST.UNIT_100_SQUARE_FEET,
  },
  Area_SY: {
    measure: CLS_CONST.QUANTITY_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_YARDS,
  },
  Area_M2: {
    measure: CLS_CONST.QUANTITY_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_METER,
  },
  Area_CM2: {
    measure: CLS_CONST.QUANTITY_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_CENTIMETER,
  },
  Area_MM2: {
    measure: CLS_CONST.QUANTITY_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_MILLIMETER,
  },
  Perimeter_IN: {
    measure: CLS_CONST.QUANTITY_PERIMETER,
    unitOverride: CLS_CONST.UNIT_INCHES,
  },
  Perimeter_FT: {
    measure: CLS_CONST.QUANTITY_PERIMETER,
    unitOverride: CLS_CONST.UNIT_FEET,
  },
  Perimeter_YD: {
    measure: CLS_CONST.QUANTITY_PERIMETER,
    unitOverride: CLS_CONST.UNIT_YARD,
  },
  Perimeter_M: {
    measure: CLS_CONST.QUANTITY_PERIMETER,
    unitOverride: CLS_CONST.UNIT_METER,
  },
  Perimeter_CM: {
    measure: CLS_CONST.QUANTITY_PERIMETER,
    unitOverride: CLS_CONST.UNIT_CENTIMETER,
  },
  Perimeter_MM: {
    measure: CLS_CONST.QUANTITY_PERIMETER,
    unitOverride: CLS_CONST.UNIT_MILLIMETER,
  },
  Volume_CF: {
    measure: CLS_CONST.QUANTITY_VOLUME,
    unitOverride: CLS_CONST.UNIT_CUBIC_FEET,
  },
  Volume_CY: {
    measure: CLS_CONST.QUANTITY_VOLUME,
    unitOverride: CLS_CONST.UNIT_CUBIC_YARD,
  },
  Volume_M3: {
    measure: CLS_CONST.QUANTITY_VOLUME,
    unitOverride: CLS_CONST.UNIT_CUBIC_METER,
  },
  Perimeter_Surface_IN2: {
    measure: CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_INCH,
  },
  Perimeter_Surface_SF: {
    measure: CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_FEET,
  },
  Perimeter_Surface_SY: {
    measure: CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_YARDS,
  },
  Perimeter_Surface_M2: {
    measure: CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_METER,
  },
  Perimeter_Surface_CM2: {
    measure: CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_CENTIMETER,
  },
  Perimeter_Surface_MM2: {
    measure: CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_MILLIMETER,
  },
  Perimeter_Surface_SQ: {
    measure: CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA,
    unitOverride: CLS_CONST.UNIT_100_SQUARE_FEET,
  },
  Tile_Count: {
    measure: CLS_CONST.QUANTITY_TILE_COUNT,
  },

  Total_Height_IN: {
    measure: CLS_CONST.QUANTITY_TOTAL_HEIGHT,
    unitOverride: CLS_CONST.UNIT_INCHES,
  },
  Total_Height_FT: {
    measure: CLS_CONST.QUANTITY_TOTAL_HEIGHT,
    unitOverride: CLS_CONST.UNIT_FEET,
  },
  Total_Height_YD: {
    measure: CLS_CONST.QUANTITY_TOTAL_HEIGHT,
    unitOverride: CLS_CONST.UNIT_YARD,
  },
  Total_Height_M: {
    measure: CLS_CONST.QUANTITY_TOTAL_HEIGHT,
    unitOverride: CLS_CONST.UNIT_METER,
  },
  Total_Height_CM: {
    measure: CLS_CONST.QUANTITY_TOTAL_HEIGHT,
    unitOverride: CLS_CONST.UNIT_CENTIMETER,
  },
  Total_Height_MM: {
    measure: CLS_CONST.QUANTITY_TOTAL_HEIGHT,
    unitOverride: CLS_CONST.UNIT_MILLIMETER,
  },

  Length_IN: {
    measure: CLS_CONST.QUANTITY_LENGTH,
    unitOverride: CLS_CONST.UNIT_INCHES,
  },
  Length_FT: {
    measure: CLS_CONST.QUANTITY_LENGTH,
    unitOverride: CLS_CONST.UNIT_FEET,
  },
  Length_YD: {
    measure: CLS_CONST.QUANTITY_LENGTH,
    unitOverride: CLS_CONST.UNIT_YARD,
  },
  Length_M: {
    measure: CLS_CONST.QUANTITY_LENGTH,
    unitOverride: CLS_CONST.UNIT_METER,
  },
  Length_CM: {
    measure: CLS_CONST.QUANTITY_LENGTH,
    unitOverride: CLS_CONST.UNIT_CENTIMETER,
  },
  Length_MM: {
    measure: CLS_CONST.QUANTITY_LENGTH,
    unitOverride: CLS_CONST.UNIT_MILLIMETER,
  },
  Vertical_Area_1Side_IN2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_1_SIDE,
    unitOverride: CLS_CONST.UNIT_SQUARE_INCH,
  },
  Vertical_Area_1Side_SF: {
    measure: CLS_CONST.QUANTITY_VER_AREA_1_SIDE,
    unitOverride: CLS_CONST.UNIT_SQUARE_FEET,
  },
  Vertical_Area_1Side_SY: {
    measure: CLS_CONST.QUANTITY_VER_AREA_1_SIDE,
    unitOverride: CLS_CONST.UNIT_SQUARE_YARDS,
  },
  Vertical_Area_1Side_M2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_1_SIDE,
    unitOverride: CLS_CONST.UNIT_SQUARE_METER,
  },
  Vertical_Area_1Side_CM2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_1_SIDE,
    unitOverride: CLS_CONST.UNIT_SQUARE_CENTIMETER,
  },
  Vertical_Area_1Side_MM2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_1_SIDE,
    unitOverride: CLS_CONST.UNIT_SQUARE_MILLIMETER,
  },
  Vertical_Area_2Sides_IN2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_2_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_INCH,
  },
  Vertical_Area_2Sides_SF: {
    measure: CLS_CONST.QUANTITY_VER_AREA_2_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_FEET,
  },
  Vertical_Area_2Sides_SY: {
    measure: CLS_CONST.QUANTITY_VER_AREA_2_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_YARDS,
  },
  Vertical_Area_2Sides_M2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_2_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_METER,
  },
  Vertical_Area_2Sides_CM2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_2_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_CENTIMETER,
  },
  Vertical_Area_2Sides_MM2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_2_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_MILLIMETER,
  },
  Vertical_Area_AllSides_IN2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_INCH,
  },
  Vertical_Area_AllSides_SF: {
    measure: CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_FEET,
  },
  Vertical_Area_AllSides_SY: {
    measure: CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_YARDS,
  },
  Vertical_Area_AllSides_M2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_METER,
  },
  Vertical_Area_AllSides_CM2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_CENTIMETER,
  },
  Vertical_Area_AllSides_MM2: {
    measure: CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES,
    unitOverride: CLS_CONST.UNIT_SQUARE_MILLIMETER,
  },
  Horizontal_Area_IN2: {
    measure: CLS_CONST.QUANTITY_HOR_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_INCH,
  },
  Horizontal_Area_SF: {
    measure: CLS_CONST.QUANTITY_HOR_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_FEET,
  },
  Horizontal_Area_SY: {
    measure: CLS_CONST.QUANTITY_HOR_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_YARDS,
  },
  Horizontal_Area_M2: {
    measure: CLS_CONST.QUANTITY_HOR_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_METER,
  },
  Horizontal_Area_CM2: {
    measure: CLS_CONST.QUANTITY_HOR_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_CENTIMETER,
  },
  Horizontal_Area_MM2: {
    measure: CLS_CONST.QUANTITY_HOR_AREA,
    unitOverride: CLS_CONST.UNIT_SQUARE_MILLIMETER,
  },
  Count_EA: {
    measure: CLS_CONST.QUANTITY_COUNT,
  },
};

export const STATIC_FORMULAS_SET = new Set(Object.keys(FORUMULA_FUNCTIONS));

export const REVERSE_MAP_LIST = {
  [CLS_CONST.QUANTITY_AREA + CLS_CONST.UNIT_SQUARE_INCH]: "Area_IN2",
  [CLS_CONST.QUANTITY_AREA + CLS_CONST.UNIT_SQUARE_FEET]: "Area_SF",
  [CLS_CONST.QUANTITY_AREA + CLS_CONST.UNIT_SQUARE_YARDS]: "Area_SY",
  [CLS_CONST.QUANTITY_AREA + CLS_CONST.UNIT_100_SQUARE_FEET]: "Area_100_SF",
  [CLS_CONST.QUANTITY_AREA + CLS_CONST.UNIT_SQUARE_METER]: "Area_M2",
  [CLS_CONST.QUANTITY_AREA + CLS_CONST.UNIT_SQUARE_CENTIMETER]: "Area_CM2",
  [CLS_CONST.QUANTITY_AREA + CLS_CONST.UNIT_SQUARE_MILLIMETER]: "Area_MM2",
  [CLS_CONST.QUANTITY_PERIMETER + CLS_CONST.UNIT_INCHES]: "Perimeter_IN",
  [CLS_CONST.QUANTITY_PERIMETER + CLS_CONST.UNIT_FEET]: "Perimeter_FT",
  [CLS_CONST.QUANTITY_PERIMETER + CLS_CONST.UNIT_YARD]: "Perimeter_YD",
  [CLS_CONST.QUANTITY_PERIMETER + CLS_CONST.UNIT_METER]: "Perimeter_M",
  [CLS_CONST.QUANTITY_PERIMETER + CLS_CONST.UNIT_CENTIMETER]: "Perimeter_CM",
  [CLS_CONST.QUANTITY_PERIMETER + CLS_CONST.UNIT_MILLIMETER]: "Perimeter_MM",

  [CLS_CONST.QUANTITY_VOLUME + CLS_CONST.UNIT_CUBIC_FEET]: "Volume_CF",
  [CLS_CONST.QUANTITY_VOLUME + CLS_CONST.UNIT_CUBIC_YARD]: "Volume_CY",
  [CLS_CONST.QUANTITY_VOLUME + CLS_CONST.UNIT_CUBIC_METER]: "Volume_M3",

  [CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA + CLS_CONST.UNIT_SQUARE_INCH]:
    "Perimeter_Surface_IN2",
  [CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA + CLS_CONST.UNIT_SQUARE_FEET]:
    "Perimeter_Surface_SF",
  [CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA + CLS_CONST.UNIT_SQUARE_YARDS]:
    "Perimeter_Surface_SY",
  [CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA + CLS_CONST.UNIT_100_SQUARE_FEET]:
    "Perimeter_Surface_SQ",
  [CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA + CLS_CONST.UNIT_SQUARE_METER]:
    "Perimeter_Surface_M2",
  [CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA +
  CLS_CONST.UNIT_SQUARE_CENTIMETER]: "Perimeter_Surface_CM2",
  [CLS_CONST.QUANTITY_PERIMETER_SURFACE_AREA +
  CLS_CONST.UNIT_SQUARE_MILLIMETER]: "Perimeter_Surface_MM2",

  [CLS_CONST.QUANTITY_TILE_COUNT + CLS_CONST.UNIT_TILE_COUT]: "Tile_Count",

  [CLS_CONST.QUANTITY_LENGTH + CLS_CONST.UNIT_INCHES]: "Length_IN",
  [CLS_CONST.QUANTITY_LENGTH + CLS_CONST.UNIT_FEET]: "Length_FT",
  [CLS_CONST.QUANTITY_LENGTH + CLS_CONST.UNIT_YARD]: "Length_YD",
  [CLS_CONST.QUANTITY_LENGTH + CLS_CONST.UNIT_METER]: "Length_M",
  [CLS_CONST.QUANTITY_LENGTH + CLS_CONST.UNIT_CENTIMETER]: "Length_CM",
  [CLS_CONST.QUANTITY_LENGTH + CLS_CONST.UNIT_MILLIMETER]: "Length_MM",

  [CLS_CONST.QUANTITY_VER_AREA_1_SIDE + CLS_CONST.UNIT_SQUARE_INCH]:
    "Vertical_Area_1Side_IN2",
  [CLS_CONST.QUANTITY_VER_AREA_1_SIDE + CLS_CONST.UNIT_SQUARE_FEET]:
    "Vertical_Area_1Side_SF",
  [CLS_CONST.QUANTITY_VER_AREA_1_SIDE + CLS_CONST.UNIT_SQUARE_YARDS]:
    "Vertical_Area_1Side_SY",
  [CLS_CONST.QUANTITY_VER_AREA_1_SIDE + CLS_CONST.UNIT_SQUARE_METER]:
    "Vertical_Area_1Side_M2",
  [CLS_CONST.QUANTITY_VER_AREA_1_SIDE + CLS_CONST.UNIT_SQUARE_CENTIMETER]:
    "Vertical_Area_1Side_CM2",
  [CLS_CONST.QUANTITY_VER_AREA_1_SIDE + CLS_CONST.UNIT_SQUARE_MILLIMETER]:
    "Vertical_Area_1Side_MM2",

  [CLS_CONST.QUANTITY_VER_AREA_2_SIDES + CLS_CONST.UNIT_SQUARE_INCH]:
    "Vertical_Area_2Sides_IN2",
  [CLS_CONST.QUANTITY_VER_AREA_2_SIDES + CLS_CONST.UNIT_SQUARE_FEET]:
    "Vertical_Area_2Sides_SF",
  [CLS_CONST.QUANTITY_VER_AREA_2_SIDES + CLS_CONST.UNIT_SQUARE_YARDS]:
    "Vertical_Area_2Sides_SY",
  [CLS_CONST.QUANTITY_VER_AREA_2_SIDES + CLS_CONST.UNIT_SQUARE_METER]:
    "Vertical_Area_2Sides_M2",
  [CLS_CONST.QUANTITY_VER_AREA_2_SIDES + CLS_CONST.UNIT_SQUARE_CENTIMETER]:
    "Vertical_Area_2Sides_CM2",
  [CLS_CONST.QUANTITY_VER_AREA_2_SIDES + CLS_CONST.UNIT_SQUARE_MILLIMETER]:
    "Vertical_Area_2Sides_MM2",

  [CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES + CLS_CONST.UNIT_SQUARE_INCH]:
    "Vertical_Area_AllSides_IN2",
  [CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES + CLS_CONST.UNIT_SQUARE_FEET]:
    "Vertical_Area_AllSides_SF",
  [CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES + CLS_CONST.UNIT_SQUARE_YARDS]:
    "Vertical_Area_AllSides_SY",
  [CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES + CLS_CONST.UNIT_SQUARE_METER]:
    "Vertical_Area_AllSides_M2",
  [CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES + CLS_CONST.UNIT_SQUARE_CENTIMETER]:
    "Vertical_Area_AllSides_CM2",
  [CLS_CONST.QUANTITY_VER_AREA_ALL_SIDES + CLS_CONST.UNIT_SQUARE_MILLIMETER]:
    "Vertical_Area_AllSides_MM2",

  [CLS_CONST.QUANTITY_HOR_AREA + CLS_CONST.UNIT_SQUARE_INCH]:
    "Horizontal_Area_IN2",
  [CLS_CONST.QUANTITY_HOR_AREA + CLS_CONST.UNIT_SQUARE_FEET]:
    "Horizontal_Area_SF",
  [CLS_CONST.QUANTITY_HOR_AREA + CLS_CONST.UNIT_SQUARE_YARDS]:
    "Horizontal_Area_SY",
  [CLS_CONST.QUANTITY_HOR_AREA + CLS_CONST.UNIT_SQUARE_METER]:
    "Horizontal_Area_M2",
  [CLS_CONST.QUANTITY_HOR_AREA + CLS_CONST.UNIT_SQUARE_CENTIMETER]:
    "Horizontal_Area_CM2",
  [CLS_CONST.QUANTITY_HOR_AREA + CLS_CONST.UNIT_SQUARE_MILLIMETER]:
    "Horizontal_Area_MM2",

  [CLS_CONST.QUANTITY_COUNT + CLS_CONST.UNIT_TILE_COUT]: "Count_EA",

  [CLS_CONST.QUANTITY_TOTAL_HEIGHT + CLS_CONST.UNIT_INCHES]: "Total_Height_IN",
  [CLS_CONST.QUANTITY_TOTAL_HEIGHT + CLS_CONST.UNIT_FEET]: "Total_Height_FT",
  [CLS_CONST.QUANTITY_TOTAL_HEIGHT + CLS_CONST.UNIT_YARD]: "Total_Height_YD",
  [CLS_CONST.QUANTITY_TOTAL_HEIGHT + CLS_CONST.UNIT_METER]: "Total_Height_M",
  [CLS_CONST.QUANTITY_TOTAL_HEIGHT + CLS_CONST.UNIT_CENTIMETER]:
    "Total_Height_CM",
  [CLS_CONST.QUANTITY_TOTAL_HEIGHT + CLS_CONST.UNIT_MILLIMETER]:
    "Total_Height_MM",
};
