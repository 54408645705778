/* eslint-env browser */
// from "NEBULA.GL"
import { Feature } from "geojson";
import {
  TextLayer,
  IconLayer,
  GeoJsonLayer,
  ScatterplotLayer,
} from "@deck.gl/layers";
import { SimpleMeshLayer } from "deck.gl";
import { FillStyleExtension } from "@deck.gl/extensions";
import { InteractiveState } from "@deck.gl/core/lib/deck";

import { noop } from "../../utils/function";
import EditableLayer from "./editable-layer";
import ScatterLayerExtension from "../ScatterLayerExtension";
import { DECK_PATTERNS } from "../../components/Controller/consts";

const TRANSPARENT_FILL = [0x0, 0x0, 0x0, 0x0];
const DEFAULT_LINE_COLOR = [0x0, 0x0, 0x0, 0x99];
const DEFAULT_FILL_COLOR = [0x0, 0x0, 0x0, 0x90];
const DEFAULT_GRAY_COLOR = [0x3c, 0x3c, 0x3c, 0xff];
const DEFAULT_WHITE_COLOR = [0xe6, 0xe6, 0xe6, 0xff];
const DEFAULT_SELECTED_LINE_COLOR = [0x0, 0x0, 0x0, 0xff];
const DEFAULT_SELECTED_FILL_COLOR = [0x0, 0x0, 0x90, 0x90];
const DEFAULT_TENTATIVE_LINE_COLOR = [0x90, 0x90, 0x90, 0xff];
const DEFAULT_TENTATIVE_FILL_COLOR = [0x90, 0x90, 0x90, 0x90];
const DEFAULT_EDITING_SNAP_POINT_COLOR = [0x00, 0x00, 0x00, 0xff];
const DEFAULT_ARC_POINT_PURPLE_COLOR = [0x97, 0x47, 0xff, 0xff];
const DEFAULT_ARC_POINT_DARK_PURPLE_COLOR = [0x46, 0x5, 0x9c, 0xff];

const DEFAULT_EDITING_SNAP_POINT_RADIUS = 1;
const DEFAULT_EDITING_EXISTING_POINT_RADIUS = 1;
const DEFAULT_EDITING_INTERMEDIATE_POINT_RADIUS = 1;
const DEFAULT_ARC_POINT_RADIUS = 2;

function guideAccessor(accessor: any) {
  if (!accessor || typeof accessor !== "function") {
    return accessor;
  }
  return (guideMaybeWrapped: any) => accessor(unwrapGuide(guideMaybeWrapped));
}

// The object handed to us from deck.gl is different depending on the version of deck.gl used, unwrap as necessary
function unwrapGuide(guideMaybeWrapped: any) {
  if (guideMaybeWrapped.__source) {
    return guideMaybeWrapped.__source.object;
  } else if (guideMaybeWrapped.sourceFeature) {
    return guideMaybeWrapped.sourceFeature.feature;
  }
  // It is not wrapped, return as is
  return guideMaybeWrapped;
}

function getEditHandleColor(handle: Feature) {
  switch (handle.properties.editHandleType) {
    case "existing":
      return handle?.properties?.type === "arc"
        ? DEFAULT_ARC_POINT_PURPLE_COLOR
        : handle?.properties?.type === "arc-control"
        ? DEFAULT_ARC_POINT_DARK_PURPLE_COLOR
        : DEFAULT_GRAY_COLOR;
    case "snap-source":
      return TRANSPARENT_FILL;
    case "magic_handle":
      return DEFAULT_WHITE_COLOR;
    case "snap":
      return TRANSPARENT_FILL;
    case "intermediate":
      return DEFAULT_GRAY_COLOR;
    default:
      return DEFAULT_GRAY_COLOR;
  }
}

function getEditHandleOutlineColor(handle: Feature) {
  switch (handle.properties.editHandleType) {
    case "existing":
      return handle?.properties?.type === "arc"
        ? DEFAULT_ARC_POINT_PURPLE_COLOR
        : handle?.properties?.type === "arc-control"
        ? DEFAULT_ARC_POINT_DARK_PURPLE_COLOR
        : DEFAULT_GRAY_COLOR;
    case "snap":
      return DEFAULT_EDITING_SNAP_POINT_COLOR;
    case "magic_handle":
    case "intermediate":
      return DEFAULT_GRAY_COLOR;
    default:
      return DEFAULT_GRAY_COLOR;
  }
}

function getEditHandleRadius(handle: Feature) {
  switch (handle.properties.editHandleType) {
    case "existing":
      return DEFAULT_EDITING_EXISTING_POINT_RADIUS;
    case "snap":
      return DEFAULT_EDITING_SNAP_POINT_RADIUS;
    case "arc":
      return DEFAULT_ARC_POINT_RADIUS;
    case "edit":
      return 10;
    case "intermediate":
    default:
      return DEFAULT_EDITING_INTERMEDIATE_POINT_RADIUS;
  }
}

const defaultProps: any = {
  onEdit: () => {},
  pickable: true,
  pickingRadius: 10,
  pickingDepth: 1,
  fp64: false,
  filled: true,
  stroked: true,
  lineWidthScale: 1,
  lineWidthMinPixels: 1,
  lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
  lineWidthUnits: "pixels",
  lineJointRounded: false,
  lineMiterLimit: 4,
  pointRadiusScale: 1,
  pointRadiusMinPixels: 10,
  pointRadiusMaxPixels: Number.MAX_SAFE_INTEGER,
  getLineColor: (feature: Feature, isSelected: boolean) =>
    isSelected ? DEFAULT_SELECTED_LINE_COLOR : DEFAULT_LINE_COLOR,
  getFillColor: (feature: Feature, isSelected: boolean) =>
    isSelected ? DEFAULT_SELECTED_FILL_COLOR : DEFAULT_FILL_COLOR,
  getPointRadius: (f: Feature) =>
    (f && f.properties && f.properties.radius) ||
    (f && f.properties && f.properties.size) ||
    1,
  getLineWidth: (f: Feature) =>
    (f && f.properties && f.properties.lineWidth) || 3,

  // Tentative feature rendering
  getTentativeLineColor: () => DEFAULT_TENTATIVE_LINE_COLOR,
  getTentativeFillColor: () => DEFAULT_TENTATIVE_FILL_COLOR,
  getTentativeLineWidth: (f: Feature) =>
    (f && f.properties && f.properties.lineWidth) || 3,

  editHandleType: "point",

  // point handles
  editHandlePointRadiusScale: 2,
  editHandlePointOutline: true,
  editHandlePointStrokeWidth: 2,
  editHandlePointRadiusMinPixels: 2,
  editHandlePointRadiusMaxPixels: 10,
  getEditHandlePointColor: getEditHandleColor,
  getEditHandlePointOutlineColor: getEditHandleOutlineColor,
  getEditHandlePointRadius: getEditHandleRadius,

  // icon handles
  editHandleIconAtlas: null,
  editHandleIconMapping: null,
  editHandleIconSizeScale: 1,
  getEditHandleIcon: (handle: Feature) => handle.properties.editHandleType,
  getEditHandleIconSize: 10,
  getEditHandleIconColor: getEditHandleColor,
  getEditHandleIconAngle: 0,

  // misc
  billboard: true,
};

export default class EditableGeojsonLayer extends EditableLayer {
  static layerName = "EditableGeoJsonLayer";
  static defaultProps = defaultProps;

  constructor(props: any) {
    super(props);

    this.onModeChange.bind(this);
    this.onLayerKeyUp.bind(this);
    this.getActiveMode.bind(this);
    this.onPropsChanged.bind(this);
    this.onLayerKeyDown.bind(this);
    this.onCanvasDataChange.bind(this);
    this.onInteractiveModeChange.bind(this);
  }

  renderLayers() {
    const polygonProps = this?.props?.extruded
      ? {
          extruded: this?.props?.extruded || false,
          getElevation: this.selectionAwareAccessor(this.props.getElevation),
          material: {
            ambient: 0.3,
            diffuse: 0.6,
            shininess: 1000,
            specularColor: [0, 0, 0],
          },
        }
      : {};

    const subLayerProps = this.getSubLayerProps({
      autoHighlight: true,
      id: "geojson",
      // Proxy most GeoJsonLayer props as-is
      data: this.props.data,
      fp64: this.props.fp64,
      filled: this.props.filled,
      stroked: this.props.stroked,
      lineWidthScale: this.props.lineWidthScale,
      lineWidthMinPixels: this.props.lineWidthMinPixels,
      lineWidthMaxPixels: this.props.lineWidthMaxPixels,
      lineWidthUnits: this.props.lineWidthUnits,
      lineJointRounded: this.props.lineJointRounded,
      lineMiterLimit: this.props.lineMiterLimit,
      pointRadiusScale: this.props.pointRadiusScale,
      pointRadiusMinPixels: this.props.pointRadiusMinPixels,
      pointRadiusMaxPixels: this.props.pointRadiusMaxPixels,
      getLineColor: this.selectionAwareAccessor(this.props.getLineColor),
      getFillColor: this.selectionAwareAccessor(this.props.getFillColor),
      getPointRadius: this.selectionAwareAccessor(this.props.getPointRadius),
      getLineWidth: this.selectionAwareAccessor(this.props.getLineWidth),

      _subLayerProps: {
        "line-strings": {
          billboard: this.props.billboard,
        },
        "polygons-stroke": {
          billboard: this.props.billboard,
        },
        "points-circle": {
          getShape: this.props.getShape,
          getSWidth: this.props.getSWidth,
          getSHeight: this.props.getSHeight,
          getSelected: this.props.getSelected,
          getRotation: this.props.getRotation,
          extensions: [new ScatterLayerExtension()],
        },
        "polygons-fill": this.props.usePatterns
          ? {
              extensions: [new FillStyleExtension({ pattern: true })],
              fillPatternMask: true,
              fillPatternEnabled: true,
              fillPatternAtlas: "/pattern_2.png",
              fillPatternMapping: DECK_PATTERNS,
              getFillPattern: guideAccessor(this.props.getGuidesFillPattern),
              getFillPatternScale: guideAccessor(
                this.props.getGuidesFillPatternScale
              ),
              getFillPatternOffset: [0, 0],
              ...polygonProps,
            }
          : { ...polygonProps },
      },

      updateTriggers: {
        getShape: [this.props.selectedFeatureIndexes, this.props.mode],
        getSWidth: [this.props.selectedFeatureIndexes, this.props.mode],
        getSHeight: [this.props.selectedFeatureIndexes, this.props.mode],
        getRotation: [this.props.selectedFeatureIndexes, this.props.mode],
        getLineColor: [this.props.selectedFeatureIndexes, this.props.mode],
        getFillColor: [this.props.selectedFeatureIndexes, this.props.mode],
        getLineWidth: [this.props.selectedFeatureIndexes, this.props.mode],
        getPointRadius: [this.props.selectedFeatureIndexes, this.props.mode],
      },
    } as any);

    let layers = [new GeoJsonLayer(subLayerProps)];

    layers = layers.concat(
      this.createGuidesLayers(),
      this.createTooltipsLayers() as ConcatArray<any>
    );

    return layers;
  }

  initializeState() {
    super.initializeState();

    this.setState({
      selectedFeatures: [],
      editHandles: [],
      cursor: "pointer",
    });
  }

  shouldUpdateState(opts: any) {
    return super.shouldUpdateState(opts) || opts.changeFlags.stateChanged;
  }

  updateState({ props, oldProps, changeFlags }: any) {
    // @ts-ignore
    super.updateState({ oldProps, props, changeFlags });

    if (changeFlags.propsOrDataChanged) {
      const modePropChanged =
        Object.keys(oldProps).length === 0 || props.mode !== oldProps.mode;
      if (modePropChanged) {
        let mode;
        if (typeof props.mode === "function") {
          // They passed a constructor/class, so new it up
          const ModeConstructor = props.mode;
          mode = new ModeConstructor();
        } else {
          // Should be an instance of EditMode in this case
          mode = props.mode;
        }

        if (mode !== this.state.mode) {
          this.setState({ mode, cursor: null });
        }
      }
    }

    let selectedFeatures = [];
    if (Array.isArray(props.selectedFeatureIndexes)) {
      // TODO: needs improved testing, i.e. checking for duplicates, NaNs, out of range numbers, ...
      selectedFeatures = props.selectedFeatureIndexes.map(
        (elem: string) => props.data.features[elem]
      );
    }

    this.setState({ selectedFeatures });
  }

  getModeProps(props: any) {
    return {
      modeConfig: props.modeConfig,
      data: props.data,
      selectedIndexes: props.selectedFeatureIndexes,
      lastPointerMoveEvent: this.state.lastPointerMoveEvent,
      cursor: this.state.cursor,
      onEdit: (editAction: any) => {
        // Force a re-render
        // This supports double-click where we need to ensure that there's a re-render between the two clicks
        // even though the data wasn't changed, just the internal tentative feature.
        this.setNeedsUpdate();
        props.onEdit(editAction);
      },
      onUpdateCursor: (cursor: any) => {
        this.setState({ cursor });
      },
      featureAccessKey: props.featureAccessKey,
    };
  }

  selectionAwareAccessor(accessor: any) {
    if (typeof accessor !== "function") {
      return accessor;
    }
    return (feature: Feature) =>
      accessor(feature, this.isFeatureSelected(feature), this.props.mode);
  }

  isFeatureSelected(feature: Feature) {
    if (!this.props.data || !this.props.selectedFeatureIndexes) {
      return false;
    }
    if (!this.props.selectedFeatureIndexes.length) {
      return false;
    }
    const featureIndex = this.props.data.features.indexOf(feature);
    return this.props.selectedFeatureIndexes.includes(featureIndex);
  }

  getPickingInfo({ info, sourceLayer }: any) {
    if (sourceLayer.id.endsWith("guides")) {
      // If user is picking an editing handle, add additional data to the info
      info.isGuide = true;
    }

    return info;
  }

  createGuidesLayers() {
    const mode = this.getActiveMode();
    const modeProps = this.getModeProps(this.props);

    const guides = mode.getGuides(modeProps);
    const guides3dMeshes = mode.get3dGuides(modeProps);

    let layers: any = [];

    if (guides?.features?.length) {
      let pointLayerProps;
      if (this.props.editHandleType === "icon") {
        pointLayerProps = {
          type: IconLayer,
          iconAtlas: this.props.editHandleIconAtlas,
          iconMapping: this.props.editHandleIconMapping,
          sizeScale: this.props.editHandleIconSizeScale,
          getIcon: guideAccessor(this.props.getEditHandleIcon),
          getSize: guideAccessor(this.props.getEditHandleIconSize),
          getColor: guideAccessor(this.props.getEditHandleIconColor),
          getAngle: guideAccessor(this.props.getEditHandleIconAngle),
        };
      } else {
        pointLayerProps = {
          type: ScatterplotLayer,
          radiusScale: this.props.editHandlePointRadiusScale,
          stroked: this.props.editHandlePointOutline,
          getLineWidth: this.props.editHandlePointStrokeWidth,
          radiusMinPixels: this.props.editHandlePointRadiusMinPixels,
          radiusMaxPixels: this.props.editHandlePointRadiusMaxPixels,
          getFillColor: guideAccessor(this.props.getEditHandlePointColor),
          getLineColor: guideAccessor(
            this.props.getEditHandlePointOutlineColor
          ),
        };
      }

      layers.push(
        new GeoJsonLayer(
          this.getSubLayerProps({
            id: "guides",
            radiusUnits: "pixels",
            pointRadiusScale: this.props.editHandlePointRadiusScale,
            pointRadiusMinPixels: this.props.editHandlePointRadiusMinPixels,
            pointRadiusMaxPixels: this.props.editHandlePointRadiusMaxPixels,
            getPointRadius: this.props.editHandlePointRadius,
            data: guides,
            fp64: this.props.fp64,
            _subLayerProps: {
              "points-circle": pointLayerProps,
              "points-icon": pointLayerProps,
              "polygons-fill": this.props.usePatterns
                ? {
                    extensions: [new FillStyleExtension({ pattern: true })],
                    fillPatternMask: true,
                    fillPatternEnabled: true,
                    fillPatternAtlas: "/pattern_2.png",
                    fillPatternMapping: DECK_PATTERNS,
                    getFillPattern: guideAccessor(
                      this.props.getGuidesFillPattern
                    ),
                    getFillPatternScale: guideAccessor(
                      this.props.getGuidesFillPatternScale
                    ),
                    getFillPatternOffset: [0, 0],
                  }
                : {},
            },
            lineWidthScale: this.props.lineWidthScale,
            lineWidthMinPixels: this.props.lineWidthMinPixels,
            lineWidthMaxPixels: this.props.lineWidthMaxPixels,
            lineWidthUnits: this.props.lineWidthUnits,
            lineJointRounded: this.props.lineJointRounded,
            lineMiterLimit: this.props.lineMiterLimit,
            getLineColor: guideAccessor(this.props.getTentativeLineColor),
            getLineWidth: guideAccessor(this.props.getTentativeLineWidth),
            getFillColor: guideAccessor(this.props.getTentativeFillColor),
          })
        )
      );
    }

    if (guides3dMeshes?.length) {
      const opacity =
        (modeProps?.modeConfig?.editorSettings?.polygonOpacity || 1) * 255;

      guides3dMeshes.forEach((guides3dmesh: any, idx: number) => {
        layers.push(
          new SimpleMeshLayer({
            id: "MeshLayer" + idx,
            data: [{}],
            getColor: [255, 255, 255, opacity],
            getPosition: (d: any) => [0, 0, 0],
            getOrientation: (d: any) => [0, 0, 0],
            mesh: guides3dmesh,
            sizeScale: 1,
            pickable: false,
            _useMeshColors: true,
            material: {
              diffuse: 1,
              ambient: 0.7,
              shininess: 0,
              specularColor: [0, 0, 0],
            },
          })
        );
      });
    }

    return layers;
  }

  createTooltipsLayers(): TextLayer<any>[] {
    const mode = this.getActiveMode();
    const tooltips = mode.getTooltips(this.getModeProps(this.props));

    const layer = new TextLayer(
      this.getSubLayerProps({
        id: "tooltips",
        data: tooltips,
      })
    );
    return [layer];
  }

  onLayerClick(event: any) {
    const { handleClick = noop } = this.getActiveMode();
    handleClick(event, this.getModeProps(this.props));
  }

  onLayerDblClick(event: any) {
    const { handleDblClick = noop } = this.getActiveMode();
    handleDblClick(event, this.getModeProps(this.props));
  }

  onLayerKeyUp(event: any) {
    const { handleKeyUp = noop } = this.getActiveMode();
    handleKeyUp(event, this.getModeProps(this.props));
  }

  onPropsChanged(oldProps: any, newProps: any) {
    const { handlePropsChanged = noop } = this.getActiveMode();
    handlePropsChanged(oldProps, newProps);
  }

  onLayerKeyDown(event: any) {
    const { handleKeyDown = noop } = this.getActiveMode();
    handleKeyDown(event, this.getModeProps(this.props));
  }

  onModeChange(event: any) {
    const { handleModeChange = noop } = this.getActiveMode();
    handleModeChange(event, this.getModeProps(this.props));
  }

  onCanvasDataChange(event: any) {
    const { handleCanvasDataChange = noop } = this.getActiveMode();
    handleCanvasDataChange(event, this.getModeProps(this.props));
  }

  onInteractiveModeChange(event: any) {
    const { handleInteractiveModeChange = noop } = this.getActiveMode();
    handleInteractiveModeChange(event, this.getModeProps(this.props));
  }

  onStartDragging(event: any) {
    const { handleStartDragging = noop } = this.getActiveMode();
    handleStartDragging(event, this.getModeProps(this.props));
  }

  onDragging(event: any) {
    const { handleDragging = noop } = this.getActiveMode();
    handleDragging(event, this.getModeProps(this.props));
  }

  onStopDragging(event: any) {
    const { handleStopDragging = noop } = this.getActiveMode();
    handleStopDragging(event, this.getModeProps(this.props));
  }

  onPointerMove(event: any) {
    this.setState({ lastPointerMoveEvent: event });
    const { handlePointerMove = noop } = this.getActiveMode();

    handlePointerMove(event, this.getModeProps(this.props));
  }

  getCursor({ isDragging }: InteractiveState, useCanvasPan: boolean) {
    if (this.state === null) {
      // Layer in 'Awaiting state'
      return;
    }

    let { cursor } = this.state;
    if (useCanvasPan) {
      cursor = isDragging ? "grabbing" : "grab";
    }
    return cursor;
  }

  getActiveMode() {
    return this.state.mode;
  }
}
