import React, { lazy, Suspense, useEffect } from "react";
import { Procore } from "src/modules/External";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import {
  setAmplitudeUserId,
  sendAppCuesPageChange,
  sendAppCuesUserProperties,
  setAmplitudeUserProperties,
  setSegmentUser,
} from "src/lib/tracking";
import AcceptInvite from "./AcceptInvite";
import CircularLoading from "sf/components/CircularLoading";
import { getUser, useAuth } from "src/modules/Auth/context";

const Editor = lazy(() => import("src/modules/Editor"));
const Licenses = lazy(() => import("src/modules/Licenses"));
const Dashboard = lazy(() => import("src/modules/Dashboard"));
const RedirectPage = lazy(() => import("src/modules/Redirect/Page"));
const Login = lazy(() => import("src/modules/Auth/Components/Login"));
const Logout = lazy(() => import("src/modules/Auth/Components/Logout"));
const Register = lazy(() => import("src/modules/Auth/Components/Register"));
const Invite = lazy(() => import("src/modules/Auth/Components/Invite"));
const VerifyEmail = lazy(() =>
  import("src/modules/Auth/Components/VerifyEmail")
);
const ResetPassword = lazy(() =>
  import("src/modules/Auth/Components/ResetPassword")
);
const ForgotPassword = lazy(() =>
  import("src/modules/Auth/Components/ForgotPassword")
);

const LazyComponent = ({ children }) => (
  <Suspense fallback={<CircularLoading />}>{children}</Suspense>
);

export default function AppRoutes() {
  const user = getUser();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    sendAppCuesPageChange();
  }, [location]);

  useEffect(() => {
    if (user?.id && user?.org?.name) {
      setAmplitudeUserId(user.email);
      const { password_old, password_new, ...sanitizedUser } = user;
      setAmplitudeUserProperties(sanitizedUser);

      sendAppCuesUserProperties(user.email, {
        ...sanitizedUser,
        organization_name: user.org.name,
        organization_type: user.org.type,
        organization_state: user.org.state,
      });

      setSegmentUser(sanitizedUser, user.org);
    }
  }, [user]);

  return (
    <div>
      <Routes>
        <Route
          path="/auth/login"
          element={
            <LazyComponent>
              <Login />
            </LazyComponent>
          }
        />
        <Route
          path="/auth/forgot-password"
          element={
            <LazyComponent>
              <ForgotPassword />
            </LazyComponent>
          }
        />
        <Route
          path="/auth/reset-password"
          element={
            <LazyComponent>
              <ResetPassword />
            </LazyComponent>
          }
        />
        <Route
          path="/invite"
          element={
            <LazyComponent>
              <Invite />
            </LazyComponent>
          }
        />
        <Route
          path="/register"
          element={
            <LazyComponent>
              <Register />
            </LazyComponent>
          }
        />
        <Route
          path="/accept_invite/*"
          element={
            <LazyComponent>
              <AcceptInvite />
            </LazyComponent>
          }
        />

        {!isAuthenticated ? (
          <Route path="*" element={<Redirect to="/auth/login" />} />
        ) : (
          <>
            <Route
              path="/auth/logout"
              element={
                <LazyComponent>
                  <Logout />
                </LazyComponent>
              }
            />
            <Route
              path="/auth/verify-email"
              element={
                <LazyComponent>
                  <VerifyEmail />
                </LazyComponent>
              }
            />
            <Route
              path="/dashboard/*"
              element={
                <LazyComponent>
                  <Dashboard />
                </LazyComponent>
              }
            />
            {[
              "/editor/:setId",
              "/editor/:setId/page/:pageId",
              "/editor/:orgId/:setId/page/:pageId",
              "/editor/:setId/page/:pageId/:headerMode",
              "/editor/:orgId/:setId/page/:pageId/:headerMode",
            ].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <LazyComponent>
                    <Editor />
                  </LazyComponent>
                }
              />
            ))}
            {[
              "/redirect/project/:projectName",
              "/redirect/project/:projectName/set/:setName",
              "/redirect/project/:projectName/set/:setName/page/:pageName",
            ].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <LazyComponent>
                    <RedirectPage />
                  </LazyComponent>
                }
              />
            ))}
            <Route
              path="/licenses"
              element={
                <LazyComponent>
                  <Licenses />
                </LazyComponent>
              }
            />
            <Route path="/external/procore/callback" element={<Procore />} />
            <Route path="*" element={<Redirect to="/dashboard/projects" />} />
          </>
        )}
      </Routes>
    </div>
  );
}

function Redirect({ to }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(to, { state: { from: location }, replace: true });
  }, [navigate, to]);

  return null;
}
