import turfBearing from "@turf/bearing";
import turfDistance from "@turf/distance";
import turfCentroid from "@turf/centroid";
import { getCoord } from "@turf/invariant";
import { Feature, Position } from "geojson";

import { roundNumber } from "../../utils/string";
import { getFeaturesTransformBox } from "./utils";
import { degToRad } from "../../utils/coordinates";
import { divide } from "../../utils/functional/math";
import { getPickedEditHandle } from "../../utils/modes";
import {
  rotateFeatures,
  rotateInplaceFeatures,
  scaleFeatures,
  translateFeatures,
} from "../edit/editUtils";

import { KEYS } from "../../consts/keysAndMouse";
import { GEOJSON_TYPES } from "../../consts/editor";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import { DEFAULT_PADDING } from "../../consts/coordinates";
import { RGBA_COLORS, RGB_COLORS } from "../../consts/style";
import { CANVAS_DATA_CHANGE_EVENTS } from "../../consts/canvas";
import { getFeatureMeasures } from "../base/ImmutableLayersData";

export class TransformMode extends GeoJsonEditMode {
  _rotationAngle = 0;
  _isDragging = false;
  _initialized = false;
  _isInvalidated = false;
  _transformState:
    | "idle"
    | "dragging"
    | "scaling"
    | "rotating"
    | "rotatingInplace" = "idle";

  _transformedFeatures: Feature[] = [];
  _guidesBeforeTransform: Feature[] = [];
  _featuresBeforeTransform: Feature[] = [];

  _transformGuides: Feature[] = [];
  _selectedSet: Set<string> = new Set();
  _selectedHandle: Feature | null = null;

  constructor() {
    super();
  }

  getGuides = (props: any) => {
    if (!this._initialized) {
      this._initialized = true;
      this._handleGetSelectedFeaturesGuides(props);
    }

    const guides: any = this._transformGuides;

    return {
      features: guides,
      type: GEOJSON_TYPES.FeatureCollection,
    };
  };

  getTooltips = (props: any) => {
    if (
      this._isDragging &&
      (this._transformState === "rotating" ||
        this._transformState === "rotatingInplace")
    ) {
      const mapCoords = props.lastPointerMoveEvent?.mapCoords;

      let angle = Number(roundNumber(Math.abs(this._rotationAngle), 2));

      const angleTooltip: any = {
        position: mapCoords,
        text: `${angle} deg`,
        size: 12,
        anchor: "middle",
        baseline: "bottom",
        offset: [0, -10],
        color: RGBA_COLORS.WHITE,
        backgroundColor: RGB_COLORS.BLACK,
      };

      return [angleTooltip];
    }

    return [];
  };

  handleStartDragging = (event: any, props: any) => {
    const { forcePanMode, isFocused, useCanvasPan } = props.modeConfig;
    if (forcePanMode || isFocused || useCanvasPan) return;

    this._isDragging = true;

    const picks = event.picks;
    const editHandle = getPickedEditHandle(picks);

    if (editHandle) {
      const editHandleType = editHandle.properties.editHandleType;
      if (editHandleType === "scale") {
        this._transformState = "scaling";
        this._selectedHandle = editHandle;
      } else if (editHandleType === "rotate") {
        this._transformState = "rotating";
        this._selectedHandle = editHandle;
      } else if (editHandleType === "rotateInplace") {
        this._transformState = "rotatingInplace";
        this._selectedHandle = editHandle;
      }
    } else if (picks.length > 0) {
      const feature = picks[0].object;
      if (feature?.properties?.mode === "scale") {
        this._transformState = "dragging";
      }
    }

    const features = props.data.features;
    const guides = this._transformGuides;

    this._transformedFeatures = [];
    this._featuresBeforeTransform = [...features];
    this._guidesBeforeTransform = [...guides];
  };

  handleDragging = (event: any, props: any) => {
    const editGuides = this._guidesBeforeTransform;
    const editFeatures = this._featuresBeforeTransform;
    const isShiftKeyPressed = event?.sourceEvent?.shiftKey;

    const mapCoords = event.mapCoords;
    const pointerDownMapCoords = event.pointerDownMapCoords;

    if (this._transformState === "dragging") {
      const diffX = mapCoords[0] - pointerDownMapCoords[0];
      const diffY = mapCoords[1] - pointerDownMapCoords[1];

      const translatedFeatures = translateFeatures(editFeatures, diffX, diffY);
      const translatedGuides = translateFeatures(editGuides, diffX, diffY);

      this._transformedFeatures = [...translatedFeatures];
      this._transformGuides = [...translatedGuides];
      this.editFeatures(translatedFeatures);
      this.applyActions(props, false);
    }

    if (this._transformState === "scaling") {
      const origin = this._getOppositeScaleHandle(this._selectedHandle);
      const originCoords = getCoord(origin);

      let scaleFactorX = 0;
      let scaleFactorY = 0;

      if (isShiftKeyPressed) {
        const { x, y } = this._getXYScaleFactor(
          originCoords,
          pointerDownMapCoords,
          mapCoords
        );

        scaleFactorX = x;
        scaleFactorY = y;
      } else {
        const scaleFactor = this._getScaleFactor(
          originCoords,
          pointerDownMapCoords,
          mapCoords
        );
        scaleFactorX = scaleFactor;
        scaleFactorY = scaleFactor;
      }

      const scaledFeatures = scaleFeatures(
        editFeatures,
        scaleFactorX,
        scaleFactorY,
        originCoords
      );

      const scaledGuides = scaleFeatures(
        editGuides,
        scaleFactorX,
        scaleFactorY,
        originCoords
      );

      this._transformedFeatures = [...scaledFeatures];
      this._transformGuides = [...scaledGuides];
      this.editFeatures(scaledFeatures);
      this.applyActions(props, false);
    }

    if (
      this._transformState === "rotating" ||
      this._transformState === "rotatingInplace"
    ) {
      const bboxFeature: Feature | null = editGuides.find(
        (f) => f.geometry.type === GEOJSON_TYPES.Polygon
      );

      if (bboxFeature) {
        const { deg, centroid, rad } = this._getRotationAngle(
          bboxFeature,
          pointerDownMapCoords,
          mapCoords,
          isShiftKeyPressed
        );

        this._rotationAngle = deg;

        let rotatedFeatures = [];
        if (this._transformState === "rotating") {
          rotatedFeatures = rotateFeatures(editFeatures, deg, centroid);
        } else {
          rotatedFeatures = rotateInplaceFeatures(editFeatures, rad, deg);
        }

        this._transformedFeatures = [...rotatedFeatures];
        this.editFeatures(rotatedFeatures);
        this.applyActions(props, false);

        const rotatedGuides = rotateFeatures(editGuides, deg, centroid);
        this._transformGuides = [...rotatedGuides];
      }
    }
  };

  handleStopDragging = (event: any, props: any) => {
    this._rotationAngle = 0;
    this._isDragging = false;

    this._selectedHandle = null;
    this._guidesBeforeTransform = [];
    this._featuresBeforeTransform = [];

    if (this._transformState !== "idle") {
      if (this._transformedFeatures.length > 0) {
        let transformedFeatures = this._transformedFeatures || [];

        if (this._transformState === "scaling") {
          transformedFeatures = transformedFeatures.map((p: any) => {
            const measures = getFeatureMeasures(
              p,
              props.modeConfig.view,
              props.modeConfig.colorMap
            );
            return {
              ...p,
              properties: {
                ...p.properties,
                ...measures,
              },
            };
          });
        }

        this.editFeatures(transformedFeatures);
        this.applyActions(props, true);
      }

      this._transformState = "idle";
    }
  };

  handleKeyDown = (event: any, props: any) => {
    const invalidateKeys = ["z", "Z", "y", "Y"];
    const ctrlOrCmd = event.metaKey || event.ctrlKey || event.altKey;

    if (event.key === KEYS.BACKSPACE) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (invalidateKeys.includes(event.key)) {
      this._isInvalidated = true;
    }

    if (event.key === KEYS.ENTER) {
      props.modeConfig.setDefaultMode();
    }

    if ((event.key === "r" || event.key === "R") && ctrlOrCmd) {
      event.preventDefault();
      event.stopPropagation();

      this._rotateAllFeaturesBy(props, 45);
      this._isInvalidated = true;
    }

    if (event.key === KEYS.BACKSPACE) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handlePointerMove = (event: any, props: any) => {
    const picks = event.picks;
    const editHandle = this._isDragging
      ? this._selectedHandle
      : getPickedEditHandle(picks);

    if (editHandle) {
      const editHandleType = editHandle.properties.editHandleType;

      if (editHandleType === "scale") {
        const position = editHandle.properties.positionIndexes[0] ?? 0;

        switch (position) {
          case 0:
            props.onUpdateCursor("nw-resize");
            break;
          case 1:
            props.onUpdateCursor("ne-resize");
            break;
          case 2:
            props.onUpdateCursor("se-resize");
            break;
          case 3:
            props.onUpdateCursor("sw-resize");
            break;
          default:
            props.onUpdateCursor("crosshair");
            break;
        }
      } else if (
        editHandleType === "rotate" ||
        editHandleType === "rotateInplace"
      ) {
        props.onUpdateCursor("crosshair");
      }
    } else if (picks.length > 0) {
      const feature = picks[0].object;
      if (feature?.properties?.mode === "scale") {
        props.onUpdateCursor("move");
      }
    } else {
      props.onUpdateCursor(null);
    }
  };

  handlePropsChanged = (_oldProps: any, newProps: any) => {
    const { selected } = newProps.modeConfig;

    const newSelectedSet = new Set(selected);

    const isSelectionDifferent =
      this._selectedSet.size !== newSelectedSet.size ||
      ![...this._selectedSet].every((id) => newSelectedSet.has(id));

    if (isSelectionDifferent || this._isInvalidated) {
      this._isInvalidated = false;
      this._handleGetSelectedFeaturesGuides(newProps);
    }
  };

  handleCanvasDataChange = (event: any, props: any) => {
    if (event?.detail?.event === CANVAS_DATA_CHANGE_EVENTS.rotate45) {
      this._rotateAllFeaturesBy(props, -45);
    }

    if (event?.detail?.event === CANVAS_DATA_CHANGE_EVENTS.rotate45InPlace) {
      this._rotateAllFeaturesBy(props, -45, true);
    }

    if (event?.detail?.event === CANVAS_DATA_CHANGE_EVENTS.history) {
      this._handleGetSelectedFeaturesGuides(props);
      this._isInvalidated = true;
    }

    if (event?.detail?.event === CANVAS_DATA_CHANGE_EVENTS.dataChange) {
      this._handleGetSelectedFeaturesGuides(props);
      this._isInvalidated = true;
    }
  };

  private _rotateAllFeaturesBy = (props: any, deg: number, inplace = false) => {
    const editFeatures = props.data.features;
    const bboxFeature: any = this._transformGuides.find(
      (f) => f.geometry.type === GEOJSON_TYPES.Polygon
    );

    if (bboxFeature && editFeatures.length > 0) {
      const rad = degToRad(deg);

      const centroid: Position = getCoord(turfCentroid(bboxFeature));
      const rotatedFeatures = inplace
        ? rotateInplaceFeatures(editFeatures, rad, deg)
        : rotateFeatures(editFeatures, deg, centroid);
      this.editFeatures(rotatedFeatures);
      this.applyActions(props, true);
      this._isInvalidated = true;
    }
  };

  private _handleGetSelectedFeaturesGuides = (props: any) => {
    const features = props.data.features;
    const {
      selected,
      isScaleEnabled,
      isRotateEnabled,
      isRotateInPlace,
      isTranslateEnabled,
    } = props.modeConfig;

    const isRotate = isRotateEnabled || isRotateInPlace;

    this._selectedSet = new Set(selected);
    const {
      bBox,
      bBoxLines,
      rotateHandle,
      cornerGuidePoints,
      rotateHandlePolygon,
      lineFromEnvelopeToRotateHandle,
    } = getFeaturesTransformBox(features, isRotateInPlace);

    this._transformGuides = [
      isTranslateEnabled && bBox,
      bBoxLines,
      isRotate && rotateHandle,
      isRotate && rotateHandlePolygon,
      isRotate && lineFromEnvelopeToRotateHandle,
      ...(isScaleEnabled ? cornerGuidePoints : []),
    ].filter(Boolean);
  };

  private _getOppositeScaleHandle = (handle: any): any => {
    const selectedHandleIndex =
      handle &&
      handle.properties &&
      Array.isArray(handle.properties.positionIndexes) &&
      handle.properties.positionIndexes[0];

    if (typeof selectedHandleIndex !== "number") {
      return null;
    }

    const guidePoints = this._transformGuides.filter(
      (fe) =>
        fe.geometry.type === GEOJSON_TYPES.Point &&
        fe.properties.editHandleType === "scale"
    );

    const guidePointCount = guidePoints.length;
    const oppositeIndex =
      (selectedHandleIndex + guidePointCount / 2) % guidePointCount;

    return (
      guidePoints.find((p) => {
        return p?.properties?.positionIndexes?.[0] === oppositeIndex;
      }) || null
    );
  };

  private _getScaleFactor(
    origin: Position,
    startCoords: Position,
    endCoords: Position
  ) {
    origin = origin.map(divide(DEFAULT_PADDING));
    startCoords = startCoords.map(divide(DEFAULT_PADDING));
    endCoords = endCoords.map(divide(DEFAULT_PADDING));

    const startDistance = turfDistance(origin, startCoords);
    const endDistance = turfDistance(origin, endCoords);
    return endDistance / startDistance;
  }

  private _getXYScaleFactor(
    origin: Position,
    startCoords: Position,
    endCoords: Position
  ): { x: number; y: number } {
    // Normalize inputs by dividing by the default padding
    origin = origin.map(divide(DEFAULT_PADDING));
    startCoords = startCoords.map(divide(DEFAULT_PADDING));
    endCoords = endCoords.map(divide(DEFAULT_PADDING));

    // Calculate the scaling factors for X and Y axes independently
    const scaleFactorX =
      (endCoords[0] - origin[0]) / (startCoords[0] - origin[0]);
    const scaleFactorY =
      (endCoords[1] - origin[1]) / (startCoords[1] - origin[1]);

    return { x: scaleFactorX, y: scaleFactorY };
  }

  private _getRotationAngle = (
    bbox: any,
    startCoords: Position,
    endCoords: Position,
    shouldSnap: boolean = false
  ): {
    deg: number;
    rad: number;
    centroid: Position;
  } => {
    const centroid: Position = getCoord(turfCentroid(bbox));
    const scaledCentroid: Position = centroid.map(divide(DEFAULT_PADDING));

    startCoords = startCoords.map(divide(DEFAULT_PADDING));
    endCoords = endCoords.map(divide(DEFAULT_PADDING));

    const bearing1 = turfBearing(scaledCentroid, startCoords);
    const bearing2 = turfBearing(scaledCentroid, endCoords);
    let deg = bearing2 - bearing1;

    if (shouldSnap) {
      // snap to 5 degree
      deg = Math.round(deg / 5) * 5;
    }

    return {
      rad: degToRad(deg),
      deg,
      centroid,
    };
  };
}
