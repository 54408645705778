import { Feature, Point, Position } from "geojson";

import bbox from "@turf/bbox";
import center from "@turf/center";
import turfUnion from "@turf/union";
import ellipse from "@turf/ellipse";
import turfBuffer from "@turf/buffer";
import turfConvex from "@turf/convex";
import turfEnvelope from "@turf/envelope";
import turfIntersect from "@turf/intersect";
import turfDifference from "@turf/difference";
import { point, lineString } from "@turf/helpers";
import turfTransformScale from "@turf/transform-scale";
import turfTransformRotate from "@turf/transform-rotate";

import {
  getCoords,
  calculateDistance,
  getAreaSquareInches,
  updateGeometryWithPadding,
} from "../../utils/coordinates";
import { RGB_COLORS } from "../../consts/style";
import { divide } from "../../utils/functional/math";
import { DEFAULT_PADDING } from "../../consts/coordinates";
import polygonToLine from "../../utils/turf/polygon-to-line";
import { GEOJSON_TYPES, HOLE_TYPE } from "../../consts/editor";
import ImmutableLayersData from "../../modes/base/ImmutableLayersData";

const PADDING = 10000;
const ABS_MIN_AREA = 20;
const AREA_TOLERANCE = 0.005;

function calculateDistanceBetweenPointAndLine(point: any, line: any) {
  const [x, y] = point;
  const [[x1, y1], [x2, y2]] = line;

  const segment_dx = x2 - x1;
  const segment_dy = y2 - y1;

  const dot_product = (x - x1) * segment_dx + (y - y1) * segment_dy;
  const segment_length_sq = segment_dx * segment_dx + segment_dy * segment_dy;
  const t = Math.max(0, Math.min(1, dot_product / segment_length_sq));
  const projected_point_x = x1 + t * segment_dx;
  const projected_point_y = y1 + t * segment_dy;

  const distance = calculateDistance(point, [
    projected_point_x,
    projected_point_y,
  ]);

  return {
    distance: distance,
    projectedPoint: [projected_point_x, projected_point_y],
  };
}

function calculateDistanceBetweenPolygons(feature1: any, feature2: any) {
  let minDistance = Infinity;

  const feature1Coords = feature1.geometry.coordinates[0];
  const feature2Coords = feature2.geometry.coordinates[0];

  for (let i = 0; i < feature1Coords.length; i++) {
    const vertex1 = feature1Coords[i];

    for (let j = 0; j < feature2Coords.length; j++) {
      const vertex2 = feature2Coords[j];

      const distance = calculateDistance(vertex1, vertex2);
      if (distance < minDistance) {
        minDistance = distance;
      }
    }
  }

  return minDistance;
}

export function getBufferedGeometry(feature: any, size = 0.2, steps = 1) {
  const s_feature = updateGeometryWithPadding(PADDING, feature);

  const buffered = turfBuffer(s_feature, size, {
    steps,
  });

  const b_feature = updateGeometryWithPadding(1 / PADDING, buffered);

  return b_feature;
}

export function getCleanedFootPrint(footprint: any) {
  try {
    const bufferedUpFootprint = getBufferedGeometry(footprint, 2);

    let cleanedFootprint: any = null;
    let holes = [];
    if (bufferedUpFootprint.geometry.type === GEOJSON_TYPES.Polygon) {
      holes = bufferedUpFootprint.geometry.coordinates
        .slice(1)
        .map((hole: any) => {
          return {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [hole],
            },
          };
        });
      cleanedFootprint = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [bufferedUpFootprint.geometry.coordinates[0]],
        },
      };
    }

    if (bufferedUpFootprint.geometry.type === GEOJSON_TYPES.MultiPolygon) {
      let largestPolygon = null;
      let largestArea = 0;
      bufferedUpFootprint.geometry.coordinates.forEach((polygon: any) => {
        const feature = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: [polygon],
          },
        };
        const area = getFeatureWithArea(feature).properties.area;

        if (area > largestArea) {
          largestArea = area;
          largestPolygon = feature;
        }
      });

      cleanedFootprint = largestPolygon;
    }

    holes = holes.map((hole: any) => {
      const bufferedHole = getBufferedGeometry(hole, 2);
      const envelope = getEnvelope(bufferedHole);
      const diff = turfDifference(envelope, footprint);
      if (diff.geometry.type === GEOJSON_TYPES.Polygon) {
        return diff;
      }
      const simplified = deconstructShape(diff, 0.1)[0];

      return simplified;
    });

    return {
      cleanedFootprint: cleanedFootprint || bufferedUpFootprint,
      holes,
    };
  } catch (e) {
    return {
      cleanedFootprint: footprint,
      holes: [],
    };
  }
}

export function getNegativeFootprint(
  props: any,
  footprint: any,
  features: any
) {
  if (!footprint || !features) return null;

  let negativeFootprint = footprint;

  features.forEach((feature: any) => {
    const intersect = turfIntersect(negativeFootprint, feature);
    if (intersect) {
      const buffered = getBufferedGeometry(feature);
      negativeFootprint = turfDifference(negativeFootprint, buffered);
    }
  });

  if (negativeFootprint.geometry.type === GEOJSON_TYPES.MultiPolygon) {
    const view = props.modeConfig.view;

    const polygons = new ImmutableLayersData([])
      .addFeature(negativeFootprint.geometry, "", null, null, "", view, [])
      .makeIntoPolygons(view, null)
      .getLayers()
      .filter((fe: any) => fe.properties.area > 100);

    return polygons;
  } else if (negativeFootprint.geometry.type === GEOJSON_TYPES.Polygon) {
    const polygon = new ImmutableLayersData([])
      .addFeature(
        negativeFootprint.geometry,
        "",
        null,
        null,
        "",
        props.modeConfig.view,
        []
      )
      .getLayers()[0];
    if (polygon.properties.area > 100) {
      return [polygon];
    }
  }

  return [];
}

export function getMagicToolInfoTooltip(footprint: any) {
  if (!footprint) return [];

  const backgroundColor = [255, 255, 255, 255];
  const color = [0, 0, 0, 255];

  const infoText = " Click within the following region to reveal features";

  const stateInfo = {
    position: [footprint.bbox[0], footprint.bbox[1]],
    text: infoText,
    size: 10,
    color,
    anchor: "start",
    baseline: "top",
    offset: [0, -20],
    backgroundColor,
  };

  return [stateInfo];
}

function getMinDistanceToFeature(target: any, feature: any) {
  const featureCoordinates = feature.geometry.coordinates[0];
  const taregtCoordinates = target.geometry.coordinates[0];

  let minDistance = Infinity;

  for (let i = 0; i < featureCoordinates.length; i++) {
    const vertex = featureCoordinates[i];

    let distance = Infinity;
    let closestVertex = null;

    for (let j = 0; j < taregtCoordinates.length - 1; j++) {
      const coord1 = taregtCoordinates[j];
      const coord2 = taregtCoordinates[j + 1];
      const distanceResults = calculateDistanceBetweenPointAndLine(vertex, [
        coord1,
        coord2,
      ]);

      if (distanceResults.distance < distance) {
        distance = distanceResults.distance;
        closestVertex = distanceResults.projectedPoint;
      }
    }

    if (distance < minDistance) {
      minDistance = distance;
    }
  }

  return minDistance;
}

function getPaddedFeature(target: any, feature: any) {
  const featureCoordinates = feature.geometry.coordinates[0];
  const taregtCoordinates = target.geometry.coordinates[0];

  const minFeaturesDistance = getMinDistanceToFeature(target, feature);
  const minPaddingDistance = 10;
  const maxPaddingDistance = Math.max(minFeaturesDistance * 2, 140);

  const shouldDoAlternate = minFeaturesDistance < minPaddingDistance;

  const updatedCoordinates = [];
  const updatedCoordinatesAlternate = [];

  for (let i = 0; i < featureCoordinates.length; i++) {
    const vertex = featureCoordinates[i];

    let distance = Infinity;
    let closestVertex = null;

    for (let j = 0; j < taregtCoordinates.length - 1; j++) {
      const coord1 = taregtCoordinates[j];
      const coord2 = taregtCoordinates[j + 1];
      const distanceResults = calculateDistanceBetweenPointAndLine(vertex, [
        coord1,
        coord2,
      ]);

      if (distanceResults.distance < distance) {
        distance = distanceResults.distance;
        closestVertex = distanceResults.projectedPoint;
      }
    }
    if (distance < maxPaddingDistance) {
      if (distance === 0) {
        closestVertex = vertex;
      }

      updatedCoordinates.push(closestVertex);

      if (shouldDoAlternate) {
        if (distance < minPaddingDistance) {
          updatedCoordinatesAlternate.push(closestVertex);
        } else {
          updatedCoordinatesAlternate.push(vertex);
        }
      }
    } else {
      updatedCoordinates.push(vertex);
      updatedCoordinatesAlternate.push(vertex);
    }
  }

  const updatedFeatures = [
    {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: [updatedCoordinates],
      },
    },
  ];

  if (shouldDoAlternate) {
    updatedFeatures.push({
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: [updatedCoordinatesAlternate],
      },
    });
  }
  return updatedFeatures;
}

function mergeRoomsFeatures(features: any): any {
  if (features.length === 0) return null;
  if (features.length === 1) return features[0];

  features.sort((a: any, b: any) => b.properties.area - a.properties.area);

  let merged = features[0];
  merged = getBufferedGeometry(merged, 0.1);

  let mergedIds = new Set([merged.properties.id]);

  while (mergedIds.size < features.length) {
    let closestFeature: any = null;
    let minDistance = Infinity;

    for (let i = 0; i < features.length; i++) {
      if (mergedIds.has(features[i].properties.id)) continue;

      let distance = Infinity;
      distance = calculateDistanceBetweenPolygons(merged, features[i]);

      if (distance < minDistance) {
        minDistance = distance;
        closestFeature = features[i];
      }
    }

    if (closestFeature) {
      const buffered = getBufferedGeometry(closestFeature, 0.1);
      const unionRes = turfUnion(merged, buffered);
      if (unionRes.geometry.type === GEOJSON_TYPES.Polygon) {
        merged = unionRes;
      } else if (unionRes.geometry.type === GEOJSON_TYPES.MultiPolygon) {
        const cleaned = getCleanedShapes([unionRes]);

        let maxArea = 0;
        let maxFeature = null;
        for (const f of cleaned) {
          const area = getFeatureWithArea(f).properties.area;
          if (area > maxArea) {
            maxArea = area;
            maxFeature = f;
          }
        }
        merged = maxFeature;
      }

      mergedIds.add(closestFeature.properties.id);
    } else {
      break;
    }
  }

  merged = getBufferedGeometry(merged, -0.1);
  merged.properties.color = [0, 0, 255];
  return merged;
}

function mergeRegionsFeatures(features: any): any {
  if (features.length === 0) return [];
  if (features.length === 1) return [features[0]];

  features.sort((a: any, b: any) => b.properties.area - a.properties.area);

  let merged = features[0];
  let mergedAlternate = features[0];
  let mergedIds = new Set([merged.properties.id]);
  let didAlternate = false;

  while (mergedIds.size < features.length) {
    let closestFeature: any = null;
    let minDistance = Infinity;

    for (let i = 0; i < features.length; i++) {
      if (mergedIds.has(features[i].properties.id)) continue;

      let distance = Infinity;
      distance = calculateDistanceBetweenPolygons(merged, features[i]);

      if (distance < minDistance) {
        minDistance = distance;
        closestFeature = features[i];
      }
    }

    if (closestFeature) {
      const intersect = turfIntersect(merged, closestFeature);
      if (intersect) {
        try {
          merged = turfUnion(merged, closestFeature);
          mergedAlternate = turfUnion(mergedAlternate, closestFeature);
        } catch (e) {
          console.error(e);
        }
      } else {
        const paddedFeatures = getPaddedFeature(merged, closestFeature);
        if (paddedFeatures.length === 2) {
          didAlternate = true;

          try {
            merged = turfUnion(merged, paddedFeatures[0]);
            merged = turfUnion(merged, closestFeature);

            mergedAlternate = turfUnion(mergedAlternate, paddedFeatures[1]);
            mergedAlternate = turfUnion(mergedAlternate, closestFeature);
          } catch (e) {
            merged = { ...merged };
            mergedAlternate = { ...mergedAlternate };
            console.error(e);
          }
        } else {
          try {
            merged = turfUnion(merged, paddedFeatures[0]);
            merged = turfUnion(merged, closestFeature);

            mergedAlternate = turfUnion(mergedAlternate, paddedFeatures[0]);
            mergedAlternate = turfUnion(mergedAlternate, closestFeature);
          } catch (e) {
            merged = { ...merged };
            mergedAlternate = { ...mergedAlternate };
            console.error(e);
          }
        }
      }

      mergedIds.add(closestFeature.properties.id);
    } else {
      break;
    }
  }

  let cleanedMerged;
  let cleanedMergedAlternate;

  if (merged.geometry.type === GEOJSON_TYPES.Polygon) {
    cleanedMerged = {
      ...merged,
      geometry: {
        ...merged.geometry,
        coordinates: [merged.geometry.coordinates[0]],
      },
    };
  } else if (merged.geometry.type === GEOJSON_TYPES.MultiPolygon) {
    cleanedMerged = {
      ...merged,
      geometry: {
        ...merged.geometry,
        coordinates: merged.geometry.coordinates.map((coords: any) => [
          coords[0],
        ]),
      },
    };
  }
  cleanedMerged.properties.color = [150, 0, 255];

  if (didAlternate) {
    if (mergedAlternate.geometry.type === GEOJSON_TYPES.Polygon) {
      cleanedMergedAlternate = {
        ...mergedAlternate,
        geometry: {
          ...mergedAlternate.geometry,
          coordinates: [mergedAlternate.geometry.coordinates[0]],
        },
      };
    } else if (mergedAlternate.geometry.type === GEOJSON_TYPES.MultiPolygon) {
      cleanedMergedAlternate = {
        ...mergedAlternate,
        geometry: {
          ...mergedAlternate.geometry,
          coordinates: mergedAlternate.geometry.coordinates.map(
            (coords: any) => [coords[0]]
          ),
        },
      };
    }
    cleanedMergedAlternate.properties.color = [150, 0, 255];
    return [cleanedMerged, cleanedMergedAlternate];
  }

  return [cleanedMerged];
}

export function getMagicFeatures(
  inputFeatures: any,
  rooms: any,
  regions: any
): any {
  if (inputFeatures.length > 0) {
    const roomsIntersect = rooms
      .reduce((acc: any, feature: any) => {
        for (const ff of inputFeatures) {
          const intersect = turfIntersect(ff, feature);
          if (intersect) {
            acc.push(feature);
            break;
          }
        }
        return acc;
      }, [])
      .map((feature: any) => ({
        ...feature,
        properties: {
          ...feature.properties,
          color: [0, 0, 255],
        },
      }));

    const mergedRoom = mergeRoomsFeatures(roomsIntersect);

    const regionsIntersect = regions
      .reduce((acc: any, feature: any) => {
        for (const ff of inputFeatures) {
          const intersect = turfIntersect(ff, feature);
          if (intersect) {
            acc.push(feature);
            break;
          }
        }
        return acc;
      }, [])
      .map((feature: any) => ({
        ...feature,
        properties: {
          ...feature.properties,
          color: [150, 0, 255],
        },
      }));

    const mergedRegions = mergeRegionsFeatures(regionsIntersect);
    // temp for now
    const validRegion = mergedRegions[mergedRegions.length - 1];

    return {
      rooms: roomsIntersect,
      regions: regionsIntersect,
      mergedFeatures: [mergedRoom, validRegion],
    };
  }

  return {
    rooms: [],
    regions: [],
    mergedFeatures: [],
  };
}

/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////

export function getFeatureWithArea(shape: any) {
  if (!shape || !shape?.geometry?.coordinates)
    return { properties: { area: 0 } };

  try {
    const coords = getCoords(shape?.geometry?.coordinates);
    const area = Math.round(
      getAreaSquareInches(GEOJSON_TYPES.Polygon, coords, 1, 1, 1)
    );

    return {
      ...shape,
      properties: {
        ...shape.properties,
        area,
      },
    };
  } catch (e) {
    return {
      ...shape,
      properties: {
        ...shape.properties,
        area: 0,
      },
    };
  }
}

export function getIou(shape1: any, shape2: any) {
  try {
    const intersection = turfIntersect(shape1, shape2);
    const intersection_area = intersection
      ? getFeatureWithArea(intersection).properties.area
      : 0;
    if (intersection_area === 0) return 0;

    const union = turfUnion(shape1, shape2);
    const union_area = getFeatureWithArea(union).properties.area;
    if (union_area === 0) return 0;

    const iou = intersection_area / union_area;

    return iou;
  } catch (e) {
    console.error("getIou error", e);
    return 0;
  }
}

function getEnvelope(shape: any) {
  const envelope = turfEnvelope(shape);
  return envelope;
}

function getConvexHull(shape: any) {
  const convex = turfConvex(shape);
  return convex;
}

function inclinationAngle(
  lineStart: number[],
  lineEnd: number[],
  vertical = false
) {
  const dx = lineEnd[0] - lineStart[0];
  const dy = lineEnd[1] - lineStart[1];

  const angleRadians = vertical ? Math.atan2(dx, dy) : Math.atan2(dy, dx);
  let angleDegrees = angleRadians * (180 / Math.PI);

  return angleDegrees;
}

function rotateFeature(shape: any, angle: number, pivot: any = null) {
  const sShape = updateGeometryWithPadding(DEFAULT_PADDING, shape);

  const rotated = turfTransformRotate(
    {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [sShape],
    },
    angle,
    {
      pivot,
    }
  );

  const mShape = updateGeometryWithPadding(
    1 / DEFAULT_PADDING,
    rotated.features[0]
  );

  return mShape;
}

export function scaleFeature(shape: any, scale: number) {
  const sShape = updateGeometryWithPadding(DEFAULT_PADDING, shape);

  const scaledFeature = turfTransformScale(
    {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [sShape],
    },
    scale
  );

  const mShape = updateGeometryWithPadding(
    1 / DEFAULT_PADDING,
    scaledFeature.features[0]
  );

  return mShape;
}

function getRotatedRectangle(shape: any) {
  try {
    const sShape = updateGeometryWithPadding(DEFAULT_PADDING, shape);
    const centerPt = center(sShape);

    const hull = getConvexHull(shape);
    const coords = hull.geometry.coordinates[0];

    const angles = [];
    for (let i = 0; i < coords.length - 1; i++) {
      const pt1 = coords[i];
      const pt2 = coords[i + 1];

      angles.push(inclinationAngle(pt1, pt2));
    }

    let baseAngle = 0;
    let envelope = getEnvelope(shape);
    let angleArea = getFeatureWithArea(envelope).properties.area;

    for (const angle of angles) {
      const rotated = rotateFeature(shape, angle, centerPt);
      const rotatedEnvelope = getEnvelope(rotated);
      const rotatedEnvelopeArea =
        getFeatureWithArea(rotatedEnvelope).properties.area;

      if (rotatedEnvelopeArea < angleArea) {
        baseAngle = angle;
        envelope = rotatedEnvelope;
        angleArea = rotatedEnvelopeArea;
      }
    }

    const rotatedEnvelope = rotateFeature(envelope, -baseAngle, centerPt);
    return rotatedEnvelope;
  } catch (e) {
    return getEnvelope(shape);
  }
}

export function getCleanedShapes(shapes: any) {
  const cleaned = [];

  for (const shape of shapes) {
    if (
      shape?.geometry?.type === GEOJSON_TYPES.Polygon &&
      shape?.geometry?.coordinates[0].length > 3
    ) {
      cleaned.push(shape);
    }

    if (shape?.geometry?.type === GEOJSON_TYPES.MultiPolygon) {
      for (const coords of shape.geometry?.coordinates) {
        if (coords[0].length > 3) {
          const newShapeCoords = coords[0];
          const holeShapesCoords = coords
            .slice(1)
            .filter((hole: any) => hole.length > 3);

          const newCoords = [newShapeCoords, ...holeShapesCoords];
          if (newShapeCoords.length > 3) {
            const newShape = {
              type: GEOJSON_TYPES.Feature,
              geometry: {
                type: GEOJSON_TYPES.Polygon,
                coordinates: newCoords,
              },
              properties: shape.properties,
            };
            cleaned.push(newShape);
          }
        }
      }
    }
  }

  return cleaned;
}

function getNegativeShapes(shape1: any, shape2: any, shape_area: number) {
  try {
    const negative = turfDifference(shape1, shape2);

    let cleaned_negative = getCleanedShapes([negative]);
    cleaned_negative = cleaned_negative.filter((c_n_s: any) => {
      const c_n_s_area = Math.abs(getFeatureWithArea(c_n_s).properties.area);

      return (
        c_n_s.geometry.type === GEOJSON_TYPES.Polygon &&
        c_n_s.geometry.coordinates[0].length > 3 &&
        c_n_s_area > ABS_MIN_AREA
      );
    });

    cleaned_negative = getCleanedShapes(
      cleaned_negative.map((c_n_s: any) => {
        try {
          const buffered = getBufferedGeometry(
            getBufferedGeometry(c_n_s, -0.1),
            0.1
          );
          return buffered;
        } catch (e) {
          return c_n_s;
        }
      })
    );

    cleaned_negative = cleaned_negative.filter((c_n_s: any) => {
      const c_n_s_area = Math.abs(getFeatureWithArea(c_n_s).properties.area);

      if (c_n_s.geometry.type !== GEOJSON_TYPES.Polygon) {
        return false;
      } else {
        if (c_n_s.geometry.coordinates[0].length < 3) {
          return false;
        }
      }

      if (c_n_s_area < ABS_MIN_AREA) {
        return false;
      }

      if (c_n_s_area / shape_area < AREA_TOLERANCE) {
        return false;
      }

      try {
        getBufferedGeometry(c_n_s, -0.1);
      } catch (e) {
        return false;
      }

      return true;
    });
    return cleaned_negative;
  } catch (e) {
    return [];
  }
}

function getFittingRotatedRectangle(shape: any, tolerance = 0.05) {
  const envelope = getRotatedRectangle(shape);
  const envelope_iou = getIou(shape, envelope);
  const iou_diff = 1 - envelope_iou;

  if (iou_diff < tolerance) {
    return {
      iou_diff,
      shape: envelope,
      is_fitted: true,
      is_rectangle: false,
    };
  }

  return {
    shape,
    iou_diff,
    is_fitted: false,
    is_rectangle: false,
  };
}

function getFittingRectangle(shape: any, tolerance = 0.05) {
  const envelope = turfEnvelope(shape);
  const envelope_iou = getIou(shape, envelope);
  const iou_diff = 1 - envelope_iou;

  if (iou_diff < tolerance) {
    return {
      iou_diff,
      shape: envelope,
      is_fitted: true,
      is_rectangle: true,
    };
  }

  return {
    shape,
    iou_diff,
    is_fitted: false,
    is_rectangle: false,
  };
}

function fitShape(shape: any, tolerance = 0.05) {
  const ftr = getFittingRectangle(shape, tolerance);
  if (ftr.is_fitted) {
    return ftr;
  }

  const ftrr = getFittingRotatedRectangle(shape, tolerance);
  if (ftrr.is_fitted) {
    return ftrr;
  }

  return {
    shape,
    iou_diff: 1,
    is_fitted: false,
  };
}

function getSimplifiedShape(shape: any, level = 0, initial_tolerance = 0.01) {
  let tolerance = initial_tolerance + 0.01 * level;

  const shape_area = getFeatureWithArea(shape).properties.area;
  const envelope = getEnvelope(shape);
  const iouToEnvelope = getIou(envelope, shape);
  tolerance = tolerance * iouToEnvelope;

  const fitted_shape = fitShape(shape, tolerance);
  const negatives = getNegativeShapes(envelope, shape, shape_area);

  if (fitted_shape.is_fitted) {
    return { shape: fitted_shape.shape, is_fitted: true };
  }

  if (negatives?.length === 0) {
    const fitted_shape_alt = fitShape(shape, tolerance * 2);
    if (fitted_shape_alt.is_fitted) {
      return { shape: fitted_shape_alt.shape, is_fitted: true };
    } else {
      return { shape, is_fitted: false };
    }
  }

  if (level === 4) {
    return { shape: envelope, is_fitted: false };
  }

  const simplified_negatives = negatives.map((n) => {
    const s_n = getSimplifiedShape(n, level + 1, tolerance);
    return s_n;
  });

  const simplified_negatives_features = simplified_negatives
    .map((s_n) => s_n.shape)
    .filter((s_n) => s_n.geometry.type === GEOJSON_TYPES.Polygon)
    .map((s_n) => {
      try {
        const bounds = bbox(s_n);
        const width = bounds[2] - bounds[0];
        const height = bounds[3] - bounds[1];

        const diff = Math.abs(
          1 - (Math.min(width, height) + 10) / Math.min(width, height)
        );
        const scaleFactor = Math.min(Math.max(1 + diff, 1.005), 1.03);

        const scaledFeature = scaleFeature(s_n, scaleFactor);
        return scaledFeature;
      } catch (e) {
        return s_n;
      }
    });

  const cleaned_shapes: any = getCleanedShapes([
    turfDifference(envelope, {
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.MultiPolygon,
        coordinates: simplified_negatives_features.map(
          (c_n_s) => c_n_s.geometry.coordinates
        ),
      },
      properties: {},
    }),
  ]).filter(
    (c_s) =>
      c_s.geometry.type === GEOJSON_TYPES.Polygon &&
      c_s.geometry.coordinates[0].length > 3 &&
      getFeatureWithArea(c_s).properties.area > ABS_MIN_AREA
  );

  const cleaned_shape = cleaned_shapes?.length ? cleaned_shapes[0] : shape;

  return {
    shape: cleaned_shape,
    is_fitted: true,
  };
}

export function deconstructShape(shape: any, initial_tolerance = 0.03) {
  try {
    const res = getSimplifiedShape(shape, 0, initial_tolerance);

    if (res.is_fitted) {
      return [res.shape];
    }

    return [shape];
  } catch (e) {
    return [shape];
  }
}

/**
 * *****************************************
 * *****************************************
 *  COMMON UTILS
 * *****************************************
 * *****************************************
 */

export function getIntersectFeatures(feature: any, props: any) {
  const selectedFeatures = props.data.features;
  const staticData = props.modeConfig.staticData;

  let intersectedFeatures = [];

  if (selectedFeatures?.length) {
    intersectedFeatures = selectedFeatures.filter((f: any) => {
      if (
        !f?.properties?.className?.includes(HOLE_TYPE) &&
        [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.MultiPolygon].includes(
          f.geometry.type
        )
      ) {
        const isIntersect = turfIntersect(f, feature);

        return isIntersect;
      }
      return false;
    });
  } else {
    for (const idx in staticData) {
      const f = staticData[idx];

      if (
        !f?.properties?.className?.includes(HOLE_TYPE) &&
        [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.MultiPolygon].includes(
          f.geometry.type
        )
      ) {
        const isIntersect = turfIntersect(f, feature);

        if (isIntersect) {
          intersectedFeatures.push(f);
          break;
        }
      }
    }
  }

  return intersectedFeatures;
}

export function getFeaturesTransformBox(features: any, isInplace: boolean) {
  if (!features?.length) return null;

  const featureCollection = {
    features: features,
    type: GEOJSON_TYPES.FeatureCollection,
  };

  const bBoxPadding = 100;
  let featuresBbox = bbox(featureCollection);

  const width = featuresBbox[2] - featuresBbox[0];
  const height = featuresBbox[3] - featuresBbox[1];
  const minSize = Math.min(width, height);
  const maxSize = Math.max(width, height);

  if (maxSize < 100) {
    featuresBbox = [
      featuresBbox[0] - bBoxPadding,
      featuresBbox[1] - bBoxPadding,
      featuresBbox[2] + bBoxPadding,
      featuresBbox[3] + bBoxPadding,
    ];
  }

  const bBox: any = {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [
        [
          [featuresBbox[0], featuresBbox[1]],
          [featuresBbox[2], featuresBbox[1]],
          [featuresBbox[2], featuresBbox[3]],
          [featuresBbox[0], featuresBbox[3]],
          [featuresBbox[0], featuresBbox[1]],
        ],
      ],
    },
    bbox: featuresBbox,
    properties: {
      opacity: 30,
      thinkness: 10,
      color: RGB_COLORS.BLACK,
      border: RGB_COLORS.BLACK,
      mode: "scale",
    },
  };

  const bBoxLines: any = polygonToLine(bBox);
  bBoxLines.properties.color = RGB_COLORS.BLACK;
  bBoxLines.properties.border = RGB_COLORS.BLACK;
  bBoxLines.properties.thinkness = 10;

  const cornerGuidePoints: Feature<Point>[] = bBox.geometry.coordinates[0]
    .map((coord: Position, coordIndex: number) => {
      if (coordIndex < 4) {
        return point(coord, {
          guideType: "editHandle",
          editHandleType: "scale",
          positionIndexes: [coordIndex],
        });
      }
      return null;
    })
    .filter(Boolean);

  const topEdgeMidpointCoords = [
    (featuresBbox[2] + featuresBbox[0]) / 2,
    featuresBbox[1],
  ];
  const longestEdgeLength = Math.round(
    Math.abs(featuresBbox[2] - featuresBbox[0])
  );

  const rotateHandleCoords = topEdgeMidpointCoords && [
    topEdgeMidpointCoords[0],
    topEdgeMidpointCoords[1] - Math.max(longestEdgeLength / 10, 100),
  ];

  const lineFromEnvelopeToRotateHandle = lineString([
    topEdgeMidpointCoords,
    rotateHandleCoords,
  ]);

  lineFromEnvelopeToRotateHandle.properties.color = RGB_COLORS.BLACK;
  lineFromEnvelopeToRotateHandle.properties.border = RGB_COLORS.BLACK;
  lineFromEnvelopeToRotateHandle.properties.thinkness = 10;

  const rotateHandle = point(rotateHandleCoords, {
    guideType: "editHandle",
    editHandleType: isInplace ? "rotateInplace" : "rotate",
  });

  const centerCoordPadded: any = rotateHandleCoords.map(
    divide(DEFAULT_PADDING)
  );

  const ellipseSize = minSize > 500 ? 1 : 0.5;
  const ellipseShape = ellipse(centerCoordPadded, ellipseSize, ellipseSize, {
    steps: 4,
    properties: {
      opacity: 255,
      thinkness: 10,
      color: RGB_COLORS.BLACK,
      border: RGB_COLORS.BLACK,
      mode: "rotate",
      guideType: "editHandle",
      editHandleType: isInplace ? "rotateInplace" : "rotate",
    },
  });

  const rotateHandlePolygon = updateGeometryWithPadding(
    1 / DEFAULT_PADDING,
    ellipseShape
  );

  return {
    bBox,
    bBoxLines,
    rotateHandle,
    cornerGuidePoints,
    rotateHandlePolygon,
    lineFromEnvelopeToRotateHandle,
  };
}
