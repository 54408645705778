import { CANVAS } from "sf/consts/events";

export const THUMBNAIL_EVENT = "THUMBNAIL_EVENT";
export const THUMBNAIL_TOGAL_EVENT = "THUMBNAIL_TOGAL_EVENT";

export const ACTIVE_CLASSIFICATION_SELECTED = "ACTIVE_CLASSIFICATION_SELECTED";

export function onActiveClassificationSelected(classification: any) {
  window.dispatchEvent(
    new CustomEvent(ACTIVE_CLASSIFICATION_SELECTED, {
      detail: {
        classification,
      },
    })
  );
}

export function onThumbnailEvent(callback: (props: any) => void) {
  window.dispatchEvent(
    new CustomEvent(THUMBNAIL_EVENT, {
      detail: {
        callback,
      },
    })
  );
}

export function onThumbnailTogalEvent() {
  window.dispatchEvent(new CustomEvent(THUMBNAIL_TOGAL_EVENT));
}

export function onCanvasDataChange(event: string, ...rest: any) {
  window.dispatchEvent(
    new CustomEvent(CANVAS.CANVAS_DATA_CHANGE, {
      detail: {
        event,
      },
      ...rest,
    })
  );
}
