import styled, { css } from "styled-components";

import { CSS } from "sf/consts/style";
import Input from "sf/components/Input";
import { Flex, Para, PointerBox } from "sf/components/Styled";
import { ReactComponent as DeleteIcon } from "src/svg/breakdown_delete.svg";
import { BREAKDOWN_LIST_MODES } from "src/modules/Editor/BreakdownPanel/utils";

export const FeatureIndicator = styled.div`
  flex: none;
  height: 100%;
  width: ${(props) => props.width || "10px"};
`;

export const IconContainer = styled(PointerBox)`
  display: flex;
  flex: none;
  position: relative;
  height: 100%;
  width: 20px;
  min-width: 20px;
  min-height: 20px;

  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.opacity || 1};
`;

export const IndicatorContainer = styled(IconContainer)``;

export const DeleteIconContainer = styled(IconContainer)`
  opacity: 1;
  path {
    stroke: ${CSS.GREY};
  }

  :hover path {
    stroke: ${CSS.ERROR};
  }
`;

export const UnitPara = styled(Para)`
  white-space: nowrap;
`;

export const ArrowContainer = styled(IconContainer)`
  height: 36px;
  width: 36px;
  transform: rotate(-90deg);
  transition: transform 0.2s ease;
  opacity: 0.7;

  cursor: ${(props) => (props.isIconVisible ? "pointer" : "default")};
  ${(props) =>
    props.isExpanded &&
    css`
      transform: rotate(0deg);
      opacity: 1;
    `};
`;

export const CountPara = styled(Para)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 3px;
  margin: 0 5px 0;
  min-width: 18px;
  min-height: 18px;
  flex-shrink: 0;

  background-color: ${CSS.MIDGREY + "75"};
  border-radius: 100px;
  text-align: center;

  ${(props) =>
    props.isBackgroundHighlited &&
    css`
      background-color: ${CSS.WHITE};
    `};
`;

export const Container = styled(Flex)`
  ${(props) =>
    props.mode === BREAKDOWN_LIST_MODES.editor &&
    css`
      border: 1px solid ${CSS.BORDER_COLOR};
      border-bottom: 0;
    `};

  width: 100%;
  position: relative;
  padding-right: ${(props) => props.paddingRight || "0"};
  background-color: ${(props) =>
    props.active ? props.color + "20" : CSS.WHITE};
  user-select: none;
  transition: box-shadow 0.2s;

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0.3;
    `};

  ${(props) =>
    props.isBackgroundHighlited &&
    css`
      background-color: ${(props) =>
        props.active || props.indicatorHovered
          ? props.color + "30"
          : CSS.MIDGREY + props.highlightOpacity};
      z-index: 1;
    `};

  ${(props) =>
    props.active &&
    css`
      box-shadow: inset 0 0 0 1px ${(props) => props.color || CSS.DARKGREY};
    `};

  :hover {
    box-shadow: inset 0 0 0 1px
      ${(props) =>
        props.indicatorHovered || props.active
          ? props.color || CSS.DARKGREY
          : CSS.DARKGREY + "75"};
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  path {
    stroke: ${CSS.DARKGREY + "60"};
  }
`;

export const StyledInput = styled(Input)`
  margin-left: 5px;
  border: 1px solid ${CSS.BORDER_COLOR};
  border-radius: ${CSS.BORDER_RADIUS};
  padding: 0 5px;

  &:focus {
    border: 1px solid ${CSS.MAINCOLOR};
  }
`;

export const UnitContainer = styled.div`
  position: relative;

  width: 50px;
  min-width: 50px;
  overflow: hidden;

  height: 100%;
  border-left: 1px solid ${CSS.BORDER_COLOR};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UnitIndicatorContainer = styled(UnitContainer)`
  width: 22px;
  min-width: 22px;
`;

export const CustomIdContainer = styled(UnitContainer)`
  justify-content: flex-start;
  min-width: 93px;
  width: 93px;
`;

export const ClsSelectContainer = styled(Flex)`
  cursor: pointer;
  overflow: hidden;
`;
