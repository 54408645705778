import qs from "qs";
import urlJoin from "url-join";

import { ERRORS_LOCALES } from "sf/i18n";

import config from "src/config";
import { getSessionId, getUserLocale } from "src/modules/Auth/context";
import { ApiError, ApiResponse } from "./types";

const HTTP_METHODS = ["get", "post", "put", "patch", "head", "delete"];

const request = HTTP_METHODS.reduce(
  (reqMethods, method) => {
    reqMethods[method] = (path: string, options = {}) =>
      requestFn(path, { ...options, method: method.toUpperCase() });

    return reqMethods;
  },
  {}
);

function serializeQueryParams(query: {
  $order?: string | [string, "asc" | "desc"];
  $where?: string | Record<string, any>;
  $attributes?: string | Record<string, any>;
}) {
  const serializedQuery = { ...query };

  if (Array.isArray(serializedQuery.$order)) {
    const [by, direction] = serializedQuery.$order;

    serializedQuery.$order = `[["${by}","${direction.toUpperCase()}"]]`;
  }

  ["$where", "$attributes"].forEach((key) => {
    if (!serializedQuery[key]) {
      return;
    }

    serializedQuery[key] = JSON.stringify(serializedQuery[key]);
  });

  return qs.stringify(serializedQuery) as string;
}

async function parseResponse(response: Response): Promise<ApiResponse> {
  const contentType = response.headers.get("Content-Type")?.toLowerCase() || "";

  if (contentType.includes("application/json")) {
    return (await response.json()) as ApiResponse;
  }

  return {
    body: await response.text(),
    error: null,
  };
}

function handleCustomError(
  response: Record<string, any>,
  localeErrors: Record<number, string>
): Error {
  const apiError = response.body?.error as ApiError | undefined;
  const errorCode = apiError?.errorCode as number | undefined;
  const errorMessage = apiError?.message || localeErrors[errorCode] || localeErrors[4001];
  const error = new Error(errorMessage);

  Object.assign(error, response);

  return error;
}

async function requestFn(
  path: string,
  options: RequestInit & {
    body?: Record<string, any> | string;
    query?: Record<string, any>;
    headers?: Record<string, string>;
  } = {}
) {
  const session: string = getSessionId();
  const localeErrors: Record<number, string> = getUserLocale()[ERRORS_LOCALES];

  options = {
    ...options,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      session,
      ...(options.headers as Record<string, string>),
    },
  };

  if (
    options.body &&
    typeof options.body !== "string" &&
    options.headers["Content-Type"] === "application/json"
  ) {
    options.body = JSON.stringify(options.body);
  }

  path = /^https?/.test(path) ? path : urlJoin(config.api.base, path);

  if (options.query) {
    path = `${path}?${serializeQueryParams(options.query)}`;
  }

  let response: Response | null = null;
  let body: {
    body: Record<string, unknown> | string;
    error: ApiError | null;
  } | null = null;

  try {
    response = await fetch(path, options);
    body = await parseResponse(response);
  } catch {
    throw handleCustomError({}, localeErrors);
  }

  const headers = response?.headers ?? new Headers();
  const parsedResponse = Object.assign({}, response, { body, headers });

  if (!response?.ok) {
    throw handleCustomError(parsedResponse, localeErrors);
  }

  return parsedResponse;
}

// @ts-ignore
window.apiRequest = request;
export default request;
